import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class UserApi extends BaseApi {
  static getAll({ limit, page } = {}) {
    return super.axios.get(Endpoint.API_ADMIN_USER_LIST, {
      params: { limit, page },
    })
  }
  static edit(data) {
    return super.axios.patch(Endpoint.API_ADMIN_USER_EDIT, data)
  }
  static remove({ id }) {
    return super.axios.delete(
      super.formatUrl(Endpoint.API_ADMIN_USER_DELETE, { id }),
    )
  }
}
