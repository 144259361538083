<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('edit_cms_menu') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField :label="t('name')" name="name" :value="name" />
            <CheckboxField
              :label="t('active')"
              name="isActive"
              :value="isActive"
            />
            <SelectField
              :label="t('type')"
              name="type"
              :value="type"
              :options="cmsTypeList"
            />
            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('edit') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isPending"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate'
import * as yup from 'yup'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'
import TextField from '../components/TextField'
import CheckboxField from '../components/CheckboxField'
import SelectField from '../components/SelectField.vue'

import {
  EDIT_CMS_MENU_MODAL,
  CMS_SUCCESS_MESSAGE,
  ResponseState,
} from '../constants'
import { CmsApi } from '../api'
import { load, makePositiveIntegerOrEmpty } from '../helpers'
import { useTranslate, useModal } from '../hooks'

export const validationSchema = yup.object({
  name: yup.string().required().max(255, 'Cannot be more than 255 characters'),
  isActive: yup.bool().required(),
  type: yup.string().required(),
})

export default {
  name: EDIT_CMS_MENU_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextField,
    CheckboxField,
    SelectField,
  },
  setup(_, context) {
    const { message, closeModal, isPending, data } = useModal(context)
    const t = useTranslate(EDIT_CMS_MENU_MODAL)
    const { id, name, isActive, cmsTypeList, cmsType } = data ?? {}

    const { meta, isSubmitting, handleSubmit, setErrors } = useForm({
      initialValues: { name, isActive, type: cmsType },
      validationSchema,
    })

    const onSubmit = async ({ name, isActive, type }) => {
      const _type = makePositiveIntegerOrEmpty(type)
      const request = {
        id,
        name,
        isActive,
        type: _type,
      }

      const { editCMSMenu } = await load({
        editCMSMenu: CmsApi.edit(request),
      })
      const { msg, state, data } = editCMSMenu

      if (state === ResponseState.ERROR) {
        message.value = msg

        Object.keys(data?.errors || {}).forEach((key) => {
          setErrors({ [key]: data.errors[key].msg })
        })

        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CMS_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    return {
      t,
      meta,
      isSubmitting,
      message,
      isPending,
      cmsTypeList,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
    }
  },
}
</script>
