<template>
  <div class="cap-modal cap-modal-wide">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('add_page') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField :label="t('title')" name="title" :value="title" />
            <TextEditor
              :label="t('content')"
              name="content"
              :value="content"
              placeholder="<html>..."
            />
            <CheckboxField
              :label="t('isActive')"
              name="isActive"
              :value="isActive"
            />
            <TextField :label="t('urlSlug')" name="urlSlug" :value="urlSlug" />
            <TextField
              :label="t('pageTitle')"
              name="pageTitle"
              :value="pageTitle"
            />
            <TextField
              :label="t('pageKeywords')"
              name="pageKeywords"
              :value="pageKeywords"
            />
            <TextField
              :label="t('pageDescription')"
              name="pageDescription"
              :value="pageDescription"
            />
            <TextField
              :label="t('pageRobots')"
              name="pageRobots"
              :value="pageRobots"
            />

            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('add') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate'

import Button from '../components/Button'
import CloseIcon from '../components/CloseIcon'
import CheckboxField from '../components/CheckboxField'
import Message from '../components/Message'
import TextField from '../components/TextField'
import TextEditor from '../components/TextEditor.vue'

import {
  CMS_PAGE_SUCCESS_MESSAGE,
  ResponseState,
  ADD_CMS_PAGE_MODAL,
} from '../constants'
import { useTranslate, useModal } from '../hooks'
import { CmsPageApi } from '../api'
import { load } from '../helpers'
import { initialValues, validationSchema } from './EditCmsPage.vue'

export default {
  name: ADD_CMS_PAGE_MODAL,
  components: {
    Button,
    CheckboxField,
    CloseIcon,
    Message,
    TextField,
    TextEditor,
  },
  setup(props, context) {
    const { message, closeModal } = useModal(context)
    const t = useTranslate(ADD_CMS_PAGE_MODAL)

    const { meta, isSubmitting, handleSubmit, setErrors } = useForm({
      initialValues,
      validationSchema,
    })

    const onSubmit = async ({
      title,
      content,
      isActive,
      urlSlug,
      pageTitle,
      pageKeywords,
      pageDescription,
      pageRobots,
    }) => {
      const request = {
        title,
        content,
        isActive,
        urlSlug,
        pageTitle,
        pageKeywords,
        pageDescription,
        pageRobots,
      }

      const { addPage } = await load({
        addPage: CmsPageApi.add(request),
      })
      const { msg, state, data } = addPage

      if (state === ResponseState.ERROR) {
        message.value = msg

        Object.keys(data?.errors || {}).forEach((key) => {
          setErrors({ [key]: data.errors[key].msg })
        })

        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CMS_PAGE_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    return {
      t,
      meta,
      isSubmitting,
      message,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      ...initialValues,
    }
  },
}
</script>
