import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class GameApi extends BaseApi {
  static getAll({ limit, page } = {}) {
    return super.axios.get(Endpoint.API_ADMIN_GAME_LIST, {
      params: { limit, page },
    })
  }
  static editGame(data) {
    return super.axios.patch(Endpoint.API_ADMIN_GAME_EDIT, data)
  }
}
