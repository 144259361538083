import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class CategoryApi extends BaseApi {
  static getAll() {
    return super.axios.get(Endpoint.API_ADMIN_GAME_CATEGORIES)
  }
  static getGameListByCategoryID({ id, ...params }) {
    const baseEndpoint = Endpoint.API_ADMIN_GAME_CATEGORIES_ID
    const endpoint = super.formatUrl(baseEndpoint, { id })

    return super.axios.get(endpoint, { params })
  }
  static add(data) {
    return super.axios.post(Endpoint.API_ADMIN_CATEGORY_ADD, data)
  }
  static edit(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CATEGORY_EDIT, data)
  }
  static remove({ id }) {
    return super.axios.delete(
      super.formatUrl(Endpoint.API_ADMIN_CATEGORY_DELETE, { id }),
    )
  }
}
