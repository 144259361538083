<template>
  <div class="form-group cap-form-input-container cap-input cap-text-editor">
    <div class="cap-text-editor-header">
      <label for="name">{{ label }}</label>
      <label>{{ t('show_layout') }}</label>
      <ToggleButton :value="showsCode" @toggle="toggleField" />
    </div>
    <Field
      v-if="showsCode"
      :id="name"
      :name="name"
      type="input"
      as="textarea"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      class="form-control form-control cap-text-editor-textarea"
      :v-model="value"
      @input="onTextAreaInput"
      @blur="onBlur"
    />
    <div v-else>
      <ckeditor
        :editor="editor"
        v-model="editorData"
        :id="name"
        :name="name"
        :placeholder="placeholder"
        @ready="onEditorReady"
        @input="handleChange"
        @destroy="onEditorDestroy"
        @blur="onBlur"
      ></ckeditor>
    </div>
    <ErrorMessage
      v-if="showsErrorMessage"
      :name="name"
      class="cap-error-message"
    />
  </div>
</template>
<script>
import { toRef, ref, computed, watch } from 'vue'
import { Field, ErrorMessage, useField } from 'vee-validate'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'

import ToggleButton from './ToggleButton.vue'

import { TEXT_EDITOR } from '../constants'
import { useTranslate } from '../hooks'

export default {
  name: TEXT_EDITOR,
  components: {
    Field,
    ErrorMessage,
    ckeditor: CKEditor.component,
    ToggleButton,
  },
  props: {
    autocomplete: String,
    label: String,
    name: String,
    value: String,
    placeholder: String,
  },
  setup(props) {
    const name = toRef(props, 'name')
    const showsCode = ref(false)
    const editor = ref(ClassicEditor)
    const editorData = ref('')
    const t = useTranslate(TEXT_EDITOR)

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name)

    const showsErrorMessage = computed(() => meta.validated)

    const toggleField = () => {
      showsCode.value = !showsCode.value
    }

    const onTextAreaInput = (e) => {
      handleChange(e.target.value)
      editorData.value = inputValue.value
    }

    const updateEditorData = () => {
      editorData.value = inputValue.value
    }

    const onEditorReady = (editor) => {
      editorData.value = inputValue.value
      window.editor = editor
    }

    const onEditorDestroy = () => {
      window.editor = undefined
    }

    const onBlur = () => {
      meta.validated = true
    }

    /*
     * The following watch is required to update Rich Text Editor's initial value (editorData.value),
     * in case of async setting of form initial values, which, basically, means waiting for API response
     * and then resetting the form. Without the watch, Rich Text Editor's initial value does not reset.
     */

    watch(
      inputValue,
      (value) => {
        if (editorData.value !== value && !meta.validated && !meta.dirty) {
          editorData.value = value
        }
      },
      { immediate: true },
    )

    return {
      editor,
      editorData,
      showsCode,
      showsErrorMessage,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      updateEditorData,
      toggleField,
      onTextAreaInput,
      onEditorReady,
      onEditorDestroy,
      onBlur,
      t,
    }
  },
}
</script>
