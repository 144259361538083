import {
  IS_PENDING,
  SET_IS_PENDING,
  CURRENT_THEME,
  SET_CURRENT_THEME,
} from '@/constants'

export const main = {
  namespaced: true,
  state: {
    [IS_PENDING]: false,
    [CURRENT_THEME]: process.env.VUE_APP_THEME.split(',')[0],
  },
  mutations: {
    [SET_IS_PENDING](state, value) {
      state[IS_PENDING] = value
    },
    [SET_CURRENT_THEME](state, value) {
      state[CURRENT_THEME] = value
    },
  },
}
