import { path, when } from 'ramda'

import { store } from '@/store'

export const getState = function () {
  return path(arguments, store.state)
}

export const getGetter = (type) => {
  return store.getters[type.join('/')]
}

export const commitMutation = (type, payload) => {
  return store.commit(
    when(Array.isArray, (type) => type.join('/'))(type),
    payload,
  )
}

export const dispatchAction = async (type, payload) => {
  const data = await store.dispatch(type.join('/'), payload)

  return data
}
