import { createApp } from 'vue'
import { i18n } from './i18n'
import { router } from './router'
import App from './App.vue'
import { FontAwesomeIcon } from '@/plugins/font-awesome'
import { store } from './store'
import { setFavicon } from './helpers'

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .component('fa', FontAwesomeIcon)

app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

app.mount('#app')

setFavicon()
