<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0.031 0.023 24.974 24.943"
    class="cap-close-icon"
  >
    <path
      d="M 0.031 1.969 L 10.511 12.479 L 0.031 22.988 L 1.95 24.966 L 12.577 14.516 L 22.998 24.966 L 25.005 23.077 L 14.526 12.479 L 24.976 2.028 L 23.042 0.023 L 12.545 10.521 L 2.014 0.023 L 0.046 1.958"
    />
  </svg>
</template>

<script>
import { CLOSE_ICON } from '@/constants'

export default {
  name: CLOSE_ICON,
}
</script>
