<template>
  <Message
    :message="categoryListMessage"
    :hasBottom="true"
    @close="closeCategoryListMessage"
  />
  <Message
    :message="editGameMessage"
    :type="MessageType.SUCCESS"
    :hasBottom="true"
    @close="closeEditGameMessage"
  />
  <table class="cap-table">
    <tr class="cap-table-head">
      <th class="cap-table-cell-small">{{ t('id') }}</th>
      <th class="cap-table-cell">{{ t('name') }}</th>
      <th class="cap-table-cell">{{ t('category') }}</th>
      <th class="cap-table-cell">{{ t('url_slug') }}</th>
      <th class="cap-table-cell">{{ t('logo_hor_url') }}</th>
      <th class="cap-table-cell">{{ t('logo_ver_url') }}</th>
      <th class="cap-table-cell-small">{{ t('new') }}</th>
      <th class="cap-table-cell-small">{{ t('hot') }}</th>
      <th class="cap-table-cell-small">{{ t('recommended') }}</th>
      <th class="cap-table-cell-small">{{ t('email') }}</th>
      <th class="cap-table-cell-small">{{ t('actions') }}</th>
    </tr>
    <tr
      v-for="{
        id,
        name,
        categoryId,
        urlSlug,
        logoHorizontalUrl,
        logoVerticalUrl,
        flags,
      } in list"
      :key="id"
      class="cap-table-row"
    >
      <th class="cap-table-cell">{{ id }}</th>
      <th class="cap-table-cell">{{ name }}</th>
      <th class="cap-table-cell">
        <span class="cap-capitalize">
          {{ categoryNameDictionary[categoryId] }}
        </span>
      </th>
      <th class="cap-table-cell">{{ urlSlug }}</th>
      <th class="cap-table-cell">
        <div v-if="logoHorizontalUrl" class="cap-table-cell-img-container">
          <img :src="logoHorizontalUrl" alt="image load failed" />
          <fa
            @click="copyURL(logoHorizontalUrl)"
            icon="copy"
            class="cap-table-url-copy-icon"
          ></fa>
        </div>
      </th>
      <th class="cap-table-cell">
        <div v-if="logoVerticalUrl" class="cap-table-cell-img-container">
          <img :src="logoVerticalUrl" alt="image load failed" />
          <fa
            @click="copyURL(logoVerticalUrl)"
            icon="copy"
            class="cap-table-url-copy-icon"
          ></fa>
        </div>
      </th>
      <th class="cap-table-cell">
        <fa v-if="flags.new" icon="check" class="cap-table-tick-icon"></fa>
        <fa v-else icon="remove" class="cap-table-cross-icon"></fa>
      </th>
      <th class="cap-table-cell">
        <fa v-if="flags.hot" icon="check" class="cap-table-tick-icon"></fa>
        <fa v-else icon="remove" class="cap-table-cross-icon"></fa>
      </th>
      <th class="cap-table-cell">
        <fa
          v-if="flags.recommended"
          icon="check"
          class="cap-table-tick-icon"
        ></fa>
        <fa v-else icon="remove" class="cap-table-cross-icon"></fa>
      </th>
      <th class="cap-table-cell">
        <fa v-if="flags.email" icon="check" class="cap-table-tick-icon"></fa>
        <fa v-else icon="remove" class="cap-table-cross-icon"></fa>
      </th>
      <th class="cap-table-cell-small cap-table-btn-group">
        <fa
          icon="edit"
          @click="
            openEditGameModal({
              id,
              name,
              categoryId,
              urlSlug,
              logoHorizontalUrl,
              logoVerticalUrl,
              flags,
            })
          "
          class="cap-table-tick-icon cap-table-action-icon"
        ></fa>
        <fa
          icon="trash-can"
          @click="openRemoveGameModal"
          class="cap-table-cross-icon cap-table-action-icon"
        ></fa>
      </th>
    </tr>
  </table>
</template>
<script>
import { ref, onBeforeMount, computed } from 'vue'
import { prop, map } from 'ramda'

import Message from '@/components/Message'

import {
  Module,
  SHOW_MODAL,
  GAME_TABLE,
  ADD_GAME_MODAL,
  EDIT_GAME_MODAL,
  REMOVE_GAME_MODAL,
  GAME_SUCCESS_MESSAGE,
  MessageType,
  CATEGORY_DICTIONARY,
  UPDATE_CATEGORY_DICTIONARY,
  ResponseState,
} from '@/constants'
import { commitMutation, copyURL, load } from '@/helpers'
import {
  useTranslate,
  useRouteQueryAction,
  useState,
  useActions,
} from '@/hooks'

export default {
  name: GAME_TABLE,
  props: {
    list: Array,
  },
  components: {
    Message,
  },
  setup() {
    const t = useTranslate(GAME_TABLE)
    const editGameMessage = ref('')
    const routeQueryAction = useRouteQueryAction()
    const categoryListMessage = ref('')

    const [categoryDictionary] = useState([
      Module.CATEGORY,
      CATEGORY_DICTIONARY,
    ])

    const [updateCategoryDictionary] = useActions([
      Module.CATEGORY,
      UPDATE_CATEGORY_DICTIONARY,
    ])

    const categoryNameDictionary = computed(() => {
      return map(prop(['name']))(categoryDictionary.value || {})
    })

    const getCategoryList = async () => {
      const { getCategoryList } = await load({
        getCategoryList: updateCategoryDictionary(),
      })

      const { state, msg } = getCategoryList

      if (state === ResponseState.ERROR) {
        categoryListMessage.value = msg
      }
    }

    const openAddGameModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_GAME_MODAL,
        isStrict: true,
      })

      routeQueryAction.add()
    }

    const openEditGameModal = ({
      id,
      name,
      categoryId,
      urlSlug,
      logoHorizontalUrl,
      logoVerticalUrl,
      flags,
    }) => {
      const { hot, recommended, email, new: isNew } = flags

      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_GAME_MODAL,
        isStrict: true,
        meta: {
          id,
          name,
          categoryId,
          urlSlug,
          logoHorizontalUrl,
          logoVerticalUrl,
          hot,
          recommended,
          email,
          isNew,
        },
      })

      routeQueryAction.edit()
    }

    const openRemoveGameModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_GAME_MODAL,
        isStrict: true,
      })

      routeQueryAction.remove()
    }

    const closeEditGameMessage = () => {
      editGameMessage.value = ''
    }

    const closeCategoryListMessage = () => {
      categoryListMessage.value = ''
    }

    onBeforeMount(async () => {
      const newEditGameMessage = sessionStorage.getItem(GAME_SUCCESS_MESSAGE)

      if (newEditGameMessage) {
        editGameMessage.value = newEditGameMessage
        sessionStorage.removeItem(GAME_SUCCESS_MESSAGE)
      }

      !categoryDictionary.value && getCategoryList()
    })

    return {
      t,
      MessageType,
      editGameMessage,
      copyURL,
      openAddGameModal,
      openEditGameModal,
      openRemoveGameModal,
      closeEditGameMessage,
      categoryListMessage,
      closeCategoryListMessage,
      categoryNameDictionary,
    }
  },
}
</script>
