<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('edit_cms_item') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField :label="t('title')" name="title" :value="title" />
            <SelectField
              :label="t('type')"
              name="cmsType"
              :value="cmsType"
              :options="cmsTypeList"
            />
            <SelectField
              :label="t('parent')"
              name="parentId"
              :value="parentId"
              :options="cmsParentIdList"
            />
            <SelectField
              :label="t('cmsMenu')"
              name="cmsMenuId"
              :value="cmsMenuId"
              :options="cmsMenuIdList"
            />
            <TextField
              v-if="rendersRedirectUrl"
              :label="t('redirectUrl')"
              name="redirectUrl"
              :value="redirectUrl"
            />
            <SelectField
              v-if="rendersCmsPageId"
              :label="t('cmsPage')"
              name="cmsPageId"
              :value="cmsPageId"
              :options="cmsPageIdList"
            />
            <CheckboxField
              :label="t('active')"
              name="isActive"
              :value="isActive"
            />
            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('edit') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'
import SelectField from '../components/SelectField.vue'
import TextField from '../components/TextField'
import CheckboxField from '../components/CheckboxField'

import {
  EDIT_CMS_ITEM_MODAL,
  CMS_SUCCESS_MESSAGE,
  ResponseState,
  URL_REGEX,
} from '../constants'
import { CmsItemApi } from '../api'
import { load, makePositiveIntegerOrEmpty } from '../helpers'
import { useTranslate, useModal } from '../hooks'

export const validationSchema = yup.object({
  title: yup.string().required().max(255, 'Cannot be more than 255 characters'),
  cmsMenuId: yup.string().required(),
  parentId: yup.string().nullable(),
  cmsType: yup.string().required(),
  isActive: yup.bool().required(),
  cmsPageId: yup.string().when('cmsType', {
    is: '3',
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  redirectUrl: yup
    .string()
    .when('cmsType', {
      is: '1',
      then: yup.string().required().matches(URL_REGEX, 'Enter a valid url'),
    })
    .when('cmsType', {
      is: '2',
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
})

export default {
  name: EDIT_CMS_ITEM_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextField,
    SelectField,
    CheckboxField,
  },
  setup(_, context) {
    const { message, closeModal, isPending, data } = useModal(context)
    const t = useTranslate(EDIT_CMS_ITEM_MODAL)
    const {
      id,
      title,
      isActive,
      type,
      parentId,
      cmsMenuId,
      cmsMenuIdList,
      cmsParentIdList,
      cmsPageIdList,
      redirectUrl,
      cmsPageId,
      cmsTypeList,
    } = data ?? {}

    const { meta, isSubmitting, handleSubmit, values, setErrors } = useForm({
      initialValues: {
        title,
        cmsMenuId,
        parentId,
        cmsType: type,
        isActive: isActive,
        redirectUrl,
        cmsPageId,
      },
      validationSchema,
    })

    const cmsTypeValue = computed(() => Number(values.cmsType))
    const rendersRedirectUrl = computed(
      () => cmsTypeValue.value === 1 || cmsTypeValue.value === 2,
    )
    const rendersCmsPageId = computed(() => cmsTypeValue.value === 3)

    const onSubmit = async ({
      title,
      cmsMenuId,
      parentId,
      cmsType,
      isActive,
      redirectUrl,
      cmsPageId,
    }) => {
      const _cmsPageId = makePositiveIntegerOrEmpty(cmsPageId)
      const _parentId = makePositiveIntegerOrEmpty(parentId)
      const _cmsType = makePositiveIntegerOrEmpty(cmsType)
      const _cmsMenuId = makePositiveIntegerOrEmpty(cmsMenuId)

      const request = {
        title,
        cmsMenuId: _cmsMenuId,
        parentId: _parentId,
        type: _cmsType,
        isActive,
        redirectUrl,
        cmsPageId: _cmsPageId,
        id,
      }

      const { editCMSMenu } = await load({
        editCMSMenu: CmsItemApi.edit(request),
      })
      const { msg, state, data } = editCMSMenu

      if (state === ResponseState.ERROR) {
        message.value = msg

        Object.keys(data?.errors || {}).forEach((key) => {
          setErrors({ [key]: data.errors[key].msg })
        })

        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CMS_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    return {
      t,
      meta,
      isSubmitting,
      message,
      isPending,
      cmsMenuIdList,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      cmsTypeList,
      rendersRedirectUrl,
      rendersCmsPageId,
      cmsParentIdList,
      cmsPageIdList,
    }
  },
}
</script>
