export const RouteQueryKey = {
  ACTION: 'action',
}

export const RouteQueryValue = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
  ADD_ITEM: 'add-item',
  EDIT_ITEM: 'edit-item',
  REMOVE_ITEM: 'remove-item',
  REARRANGE_ITEMS: 'rearrange-items',
}
