import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class AuthApi extends BaseApi {
  static login(data) {
    return super.axios.post(Endpoint.API_ADMIN_LOGIN, data)
  }
  static logout() {
    return super.axios.get(Endpoint.API_ADMIN_LOGOUT)
  }
}
