export const Module = {
  AUTH: 'AUTH',
  CATEGORY: 'CATEGORY',
  // GAME: 'GAME',
  MAIN: 'MAIN',
  MODAL: 'MODAL',
  SLIDESHOW: 'SLIDESHOW',
}

export const IS_AUTHORIZED = 'IS_AUTHORIZED'
export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'

export const CATEGORY_DICTIONARY = 'CATEGORY_DICTIONARY'
export const UPDATE_CATEGORY_DICTIONARY = 'UPDATE_CATEGORY_DICTIONARY'

// export const GAME_DICTIONARY = 'GAME_DICTIONARY'
// export const UPDATE_GAME_DICTIONARY = 'UPDATE_GAME_DICTIONARY'

export const SLIDESHOW_DICTIONARY = 'SLIDESHOW_DICTIONARY'
export const UPDATE_SLIDESHOW_DICTIONARY = 'UPDATE_SLIDESHOW_DICTIONARY'

export const IS_PENDING = 'IS_PENDING'
export const SET_IS_PENDING = 'SET_IS_PENDING'
export const CURRENT_THEME = 'CURRENT_THEME'
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const DROP_MODAL = 'DROP_MODAL'
export const TRIGGER_MODAL_REMOVAL = 'TRIGGER_MODAL_REMOVAL'
export const MODAL_LIST = 'MODAL_LIST'
export const IS_MODAL_REMOVAL_PENDING = 'IS_MODAL_REMOVAL_PENDING'
export const CURRENT_MODAL = 'CURRENT_MODAL'
