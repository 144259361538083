<template>
  <div class="cap-panel-view">
    <div class="cap-panel-view-header">{{ t('cms_title') }}</div>
    <div class="cap-panel-view-add-button-group">
      <button @click="openAddCmsMenuModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_cms_menu') }}</span>
      </button>
      <button @click="openAddCmsItemModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_cms_item') }}</span>
      </button>
    </div>
    <Message
      :message="editCMSMessage"
      :type="editCMSMessageType"
      :hasBottom="true"
      @close="closeEditCMSMessage"
    />
    <Message
      :message="pageListMessage"
      :hasBottom="true"
      @close="closePageListMessage"
    />
    <div v-if="isNilList">
      <Loading />
    </div>
    <Message
      v-else-if="listMessage"
      :message="listMessage"
      :hasBottom="true"
      @close="closeUpdateListMessage"
    />
    <div v-else-if="isEmptyList">{{ t('empty_list') }}</div>

    <table v-else class="cap-table">
      <tr class="cap-table-head">
        <th class="cap-table-cell-small">{{ t('id') }}</th>
        <th colspan="4" class="cap-table-cell">{{ t('name') }}</th>
        <th class="cap-table-cell-small">{{ t('type') }}</th>
        <th class="cap-table-cell-small">{{ t('active') }}</th>
        <th class="cap-table-cell-small">{{ t('actions') }}</th>
      </tr>
      <template
        v-for="{ id, name, isActive, items, isOpen, type } in list"
        :key="id"
      >
        <tr>
          <td class="cap-table-cell-small">{{ id }}</td>
          <td colspan="4" class="cap-table-cell">{{ name }}</td>
          <td class="cap-table-cell-small">{{ cmsTypeDictionary[type] }}</td>
          <td class="cap-table-cell-small">
            <div class="cap-table-cell-container-centered">
              <ToggleButton
                :value="isActive"
                @toggle="changeActiveState({ id, isActive })"
              />
            </div>
          </td>
          <td class="cap-table-cell-small cap-table-btn-group">
            <div class="cap-table-cell-container-centered">
              <fa
                icon="edit"
                @click="openEditCmsMenuModal({ id, name, isActive, type })"
                class="cap-table-tick-icon cap-table-tick-icon"
              ></fa>
              <fa
                icon="trash-can"
                @click="openRemoveCmsMenuModal({ id, name })"
                class="cap-table-cross-icon cap-table-action-icon"
              ></fa>
              <fa
                icon="align-justify"
                @click="rearrangeChildren({ id, name, items })"
                class="cap-table-bars"
                :class="{ 'cap-table-disabled-icon': items.length < 2 }"
              ></fa>
              <fa
                v-if="isOpen"
                icon="angle-up"
                @click="toggleSubtable({ id })"
                class="cap-table-angle-icon cap-table-action-icon"
              ></fa>
              <fa
                v-else
                icon="angle-down"
                @click="toggleSubtable({ id })"
                class="cap-table-tick-icon cap-table-angle-icon cap-table-action-icon"
              ></fa>
            </div>
          </td>
        </tr>
        <CMSSublistTable
          :isOpen="isOpen"
          :items="items"
          :cmsMenuId="id"
          :cmsMenuIdList="cmsMenuIdList"
          :cmsParentIdList="cmsParentIdList"
          :cmsPageIdList="cmsPageIdList"
          :cmsTypeList="cmsTypeList"
          :cmsTypeDictionary="cmsTypeDictionary"
          :cmsPageIdDictionary="cmsPageIdDictionary"
          @processCMSItemStateChange="processCMSItemStateChange"
        />
      </template>
    </table>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from 'vue'
import {
  isNil,
  isEmpty,
  map,
  pipe,
  indexBy,
  prop,
  values,
  flatten,
  prepend,
  pluck,
  ifElse,
  always,
} from 'ramda'

import Message from '@/components/Message'
import Loading from '@/components/Loading'
import ToggleButton from '@/components/ToggleButton'
import CMSSublistTable from './components/CMSSublistTable'

import {
  CMS_COMPONENT,
  ResponseState,
  MessageType,
  CMS_SUCCESS_MESSAGE,
  RouteQueryKey,
  Module,
  SHOW_MODAL,
  ADD_CMS_MENU_MODAL,
  EDIT_CMS_MENU_MODAL,
  REMOVE_CMS_MENU_MODAL,
  ADD_CMS_ITEM_MODAL,
  cmsTypeList,
  REARRANGE_CMS_ITEM_MODAL,
} from '../../../../constants'
import {
  useTranslate,
  useCurrentRoute,
  useRouteQueryAction,
} from '../../../../hooks'
import { commitMutation, load } from '../../../../helpers'
import { CmsApi, CmsPageApi } from '../../../../api'

export default {
  name: CMS_COMPONENT,
  components: {
    Message,
    Loading,
    ToggleButton,
    CMSSublistTable,
  },
  setup() {
    const t = useTranslate(CMS_COMPONENT)
    const list = ref(null)
    const pageList = ref(null)

    const listMessage = ref('')
    const editCMSMessageType = ref(MessageType.SUCCESS)
    const editCMSMessage = ref('')
    const pageListMessage = ref('')

    const currentRoute = useCurrentRoute()
    const routeQueryAction = useRouteQueryAction()

    const isNilList = computed(() => isNil(list.value))
    const isEmptyList = computed(() => isEmpty(list.value))
    const queryParams = computed(() => currentRoute.value.query)
    const cmsMenuIdList = computed(() =>
      pipe(
        values,
        map(({ id, name }) => ({ option: name, value: id })),
      )(list.value || {}),
    )
    const cmsParentIdList = computed(() =>
      pipe(
        map((menu) => menu.items),
        values,
        flatten,
        map(({ id, title }) => ({ option: title, value: id })),
        prepend({ option: '', value: -1 }),
      )(list.value || {}),
    )
    const cmsPageIdList = computed(() => {
      return map(({ id, name }) => ({ option: name, value: id }))(
        pageList.value || [],
      )
    })
    const cmsTypeListTranslated = computed(() =>
      map(({ option, value }) => ({
        option: t(option),
        value,
      }))(cmsTypeList || {}),
    )
    const cmsTypeDictionary = computed(() =>
      pipe(
        indexBy(prop('value')),
        pluck('option'),
      )(cmsTypeListTranslated.value),
    )
    const cmsPageIdDictionary = computed(() =>
      pipe(ifElse(isNil, always({}), pipe(indexBy(prop('id')), pluck('name'))))(
        pageList.value,
      ),
    )

    const openAddCmsMenuModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_CMS_MENU_MODAL,
        isStrict: true,
        meta: {
          cmsTypeList: cmsTypeListTranslated,
        },
      })

      routeQueryAction.add()
    }

    const openEditCmsMenuModal = ({ id, name, isActive, type: cmsType }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_CMS_MENU_MODAL,
        isStrict: true,
        meta: {
          id,
          name,
          isActive,
          cmsTypeList: cmsTypeListTranslated,
          cmsType,
        },
      })

      routeQueryAction.edit()
    }

    const openRemoveCmsMenuModal = ({ id, name }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_CMS_MENU_MODAL,
        isStrict: true,
        meta: { id, name },
      })

      routeQueryAction.remove()
    }

    const openAddCmsItemModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_CMS_ITEM_MODAL,
        isStrict: true,
        meta: {
          cmsMenuIdList,
          cmsParentIdList,
          cmsPageIdList,
          cmsTypeList: cmsTypeListTranslated,
        },
      })

      routeQueryAction.addItem()
    }

    const updateList = async () => {
      const loaderList = {
        getList: CmsApi.getAll(),
      }

      const { getList } = await load(loaderList)
      const { data, state, msg } = getList
      if (state === ResponseState.ERROR) {
        listMessage.value = msg
        return
      }

      list.value = pipe(
        map((item) => {
          return { ...item, isOpen: false }
        }),
        indexBy(prop('id')),
      )(data.items)
    }

    const getPageList = async () => {
      const loaderList = {
        getList: CmsPageApi.getAll(),
      }

      const { getList } = await load(loaderList)
      const { data, state, msg } = getList
      if (state === ResponseState.ERROR) {
        pageListMessage.value = msg
        return
      }

      pageList.value = data.items
    }

    const changeActiveState = async ({ id, isActive }) => {
      const loaderList = {
        changeState: CmsApi.changeState({
          id,
          isActive: !isActive,
        }),
      }

      const { changeState } = await load(loaderList)
      const { state, msg } = changeState

      editCMSMessageType.value =
        state === ResponseState.OK ? MessageType.SUCCESS : MessageType.FAILURE
      editCMSMessage.value = msg

      if (ResponseState.OK === state) {
        updateList()
      }
    }

    const toggleSubtable = ({ id }) => {
      list.value[id].isOpen = !list.value[id].isOpen
    }

    const closeEditCMSMessage = () => {
      editCMSMessage.value = ''
    }
    const closeListMessage = () => {
      listMessage.value = ''
    }
    const closePageListMessage = () => {
      pageListMessage.value = ''
    }

    const rearrangeChildren = ({ id, name, items }) => {
      if (items.length < 2) {
        return
      }

      const childrenList = pipe(
        indexBy(prop('position')),
        map(({ id, title, position }) => ({
          itemId: id, //NOTE: this is real cms item id
          name: title,
          id: position, //NOTE: this id is needed for draggable component, i.e. position
        })),
        values,
      )(items)

      if (childrenList)
        commitMutation([Module.MODAL, SHOW_MODAL], {
          component: REARRANGE_CMS_ITEM_MODAL,
          isStrict: true,
          meta: { id, name, childrenList },
        })

      routeQueryAction.rearrangeItems()
    }

    const processCMSItemStateChange = ({ message, messateType }) => {
      editCMSMessage.value = message
      editCMSMessageType.value = messateType

      messateType === MessageType.SUCCESS && updateList()
    }

    watch(
      () => queryParams,
      (queryParams) => {
        if (queryParams[RouteQueryKey.ACTION]) {
          editCMSMessage.value && (editCMSMessage.value = '')
          editCMSMessageType.value !== MessageType.SUCCESS &&
            (editCMSMessageType.value = MessageType.SUCCESS)

          return
        }

        const newEditCMSPageMessage = sessionStorage.getItem(
          CMS_SUCCESS_MESSAGE,
        )

        if (newEditCMSPageMessage) {
          editCMSMessage.value = newEditCMSPageMessage
          editCMSMessageType.value !== MessageType.SUCCESS &&
            (editCMSMessageType.value = MessageType.SUCCESS)

          sessionStorage.removeItem(CMS_SUCCESS_MESSAGE)
          updateList()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    onMounted(() => {
      updateList()
      getPageList()
    })

    return {
      t,
      listMessage,
      isNilList,
      isEmptyList,
      list,
      toggleSubtable,
      changeActiveState,
      editCMSMessageType,
      editCMSMessage,
      closeEditCMSMessage,
      openAddCmsMenuModal,
      openEditCmsMenuModal,
      openRemoveCmsMenuModal,
      openAddCmsItemModal,
      cmsMenuIdList,
      processCMSItemStateChange,
      closePageListMessage,
      closeListMessage,
      cmsParentIdList,
      cmsPageIdList,
      cmsTypeList: cmsTypeListTranslated,
      cmsTypeDictionary,
      cmsPageIdDictionary,
      rearrangeChildren,
      pageListMessage,
    }
  },
}
</script>
