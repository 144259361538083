<template>
  <div>
    <ModalBackground
      :isActive="isActive"
      :closesModal="hidesModalOnOutsideClick"
      @close="hideModal"
    ></ModalBackground>
    <ModalContent
      :isActive="isActive"
      :isRemovalPending="IS_MODAL_REMOVAL_PENDING"
    >
      <component :is="componentName" @close="hideModal"></component>
    </ModalContent>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { isNil } from 'ramda'
import {
  Module,
  CURRENT_MODAL,
  IS_MODAL_REMOVAL_PENDING,
  TRIGGER_MODAL_REMOVAL,
  HIDE_MODAL,
  MODAL_COMPONENT,
} from '@/constants'
import * as ModalName from '@/constants/modalName'
import { requestTimeout, cancelTimeout, callFn, dest } from '@/helpers'
import ModalBackground from './components/ModalBackground.vue'
import ModalContent from './components/ModalContent'
import AddCategory from '@/modals/AddCategory'
import EditCategory from '@/modals/EditCategory'
import RemoveCategory from '@/modals/RemoveCategory'
import AddCmsPage from '@/modals/AddCmsPage'
import EditCmsPage from '@/modals/EditCmsPage'
import RemoveCmsPage from '@/modals/RemoveCmsPage'
import AddGame from '@/modals/AddGame'
import EditGame from '@/modals/EditGame'
import RemoveGame from '@/modals/RemoveGame'
import AddSlide from '@/modals/AddSlide'
import RemoveSlide from '@/modals/RemoveSlide'
import EditUser from '@/modals/EditUser'
import RemoveUser from '@/modals/RemoveUser'
import AddCmsMenu from '@/modals/AddCmsMenu'
import EditCmsMenu from '@/modals/EditCmsMenu'
import RemoveCmsMenu from '@/modals/RemoveCmsMenu'
import AddCmsItem from '@/modals/AddCmsItem'
import EditCmsItem from '@/modals/EditCmsItem'
import RemoveCmsItem from '@/modals/RemoveCmsItem'
import RearrangeCmsItem from '@/modals/RearrangeCmsItemModal'

const requestInitialValue = null

export default {
  name: MODAL_COMPONENT,
  components: {
    ModalBackground,
    ModalContent,
    [ModalName.ADD_CATEGORY_MODAL]: AddCategory,
    [ModalName.EDIT_CATEGORY_MODAL]: EditCategory,
    [ModalName.REMOVE_CATEGORY_MODAL]: RemoveCategory,
    [ModalName.ADD_CMS_ITEM_MODAL]: AddCmsItem,
    [ModalName.EDIT_CMS_ITEM_MODAL]: EditCmsItem,
    [ModalName.REMOVE_CMS_ITEM_MODAL]: RemoveCmsItem,
    [ModalName.ADD_CMS_MENU_MODAL]: AddCmsMenu,
    [ModalName.EDIT_CMS_MENU_MODAL]: EditCmsMenu,
    [ModalName.REMOVE_CMS_MENU_MODAL]: RemoveCmsMenu,
    [ModalName.ADD_CMS_PAGE_MODAL]: AddCmsPage,
    [ModalName.EDIT_CMS_PAGE_MODAL]: EditCmsPage,
    [ModalName.REMOVE_CMS_PAGE_MODAL]: RemoveCmsPage,
    [ModalName.ADD_GAME_MODAL]: AddGame,
    [ModalName.EDIT_GAME_MODAL]: EditGame,
    [ModalName.REMOVE_GAME_MODAL]: RemoveGame,
    [ModalName.ADD_SLIDESHOW_MODAL]: AddSlide,
    [ModalName.REMOVE_SLIDESHOW_MODAL]: RemoveSlide,
    [ModalName.EDIT_USER_MODAL]: EditUser,
    [ModalName.REMOVE_USER_MODAL]: RemoveUser,
    [ModalName.REARRANGE_CMS_ITEM_MODAL]: RearrangeCmsItem,
  },
  data: () => ({
    modalHidingRequest: requestInitialValue,
  }),
  computed: {
    ...mapState(Module.MODAL, { IS_MODAL_REMOVAL_PENDING }),
    ...mapGetters({ currentModal: dest([Module.MODAL, CURRENT_MODAL]) }),
    isActive() {
      return !isNil(this.currentModal)
    },
    hidesModalOnOutsideClick() {
      return !this.currentModal?.isStrict
    },
    componentName() {
      return this.currentModal?.component
    },
  },
  watch: {
    currentModal(value, prevValue) {
      if (
        !isNil(prevValue) &&
        value?.component !== prevValue?.component &&
        this.modalHidingRequest
      ) {
        this.clearModalHiding()
      }
    },
  },
  methods: {
    ...mapMutations(Module.MODAL, [HIDE_MODAL]),
    ...mapMutations(Module.MODAL, [TRIGGER_MODAL_REMOVAL]),
    hideModal(fn) {
      const handler = () => {
        this.HIDE_MODAL()
        callFn(fn)
      }

      this.TRIGGER_MODAL_REMOVAL()
      this.$nextTick(() => {
        this.modalHidingRequest = requestTimeout(handler, 200)
      })
    },
    async clearModalHiding() {
      await cancelTimeout(this.modalHidingRequest)
      this.modalHidingRequest = requestInitialValue
    },
  },
}
</script>
