<template>
  <template v-if="isOpen">
    <tr v-if="hasNoList" class="cap-menu-table-row">
      <td colspan="8">{{ t('empty_list') }}</td>
    </tr>
    <template v-else>
      <tr class="cap-table-head cap-menu-table-head">
        <th class="cap-table-cell-small">{{ t('id') }}</th>
        <th colspan="2" class="cap-table-cell">{{ t('title') }}</th>
        <th class="cap-table-cell-small">{{ t('type') }}</th>
        <th class="cap-table-cell-small">{{ t('redirectUrl') }}</th>
        <th class="cap-table-cell-small">{{ t('cmsPage') }}</th>
        <th class="cap-table-cell-small">{{ t('active') }}</th>
        <th class="cap-table-cell-small">{{ t('actions') }}</th>
      </tr>
      <template
        v-for="{
          id,
          title,
          isActive,
          items,
          isOpen,
          type,
          redirectUrl,
          cmsPageId,
        } in renderedList"
        :key="id"
      >
        <tr class="cap-menu-table-row">
          <td class="cap-table-cell-small">{{ id }}</td>
          <td colspan="2" class="cap-table-cell">{{ title }}</td>
          <td class="cap-table-cell-small">{{ cmsTypeDictionary[type] }}</td>
          <td class="cap-table-cell-small">{{ redirectUrl }}</td>
          <td class="cap-table-cell-small">
            {{ cmsPageIdDictionary[cmsPageId] }}
          </td>
          <td class="cap-table-cell-small">
            <div class="cap-table-cell-container-centered">
              <ToggleButton
                :value="isActive"
                @toggle="changeActiveState({ id, isActive })"
              />
            </div>
          </td>
          <td class="cap-table-cell-small cap-table-btn-group">
            <div class="cap-table-cell-container-centered">
              <!-- NOTE: type 3 means Slug type -->
              <fa
                v-if="type === 3"
                icon="eye"
                class="cap-table-action-icon cap-table-eye"
              ></fa>
              <fa
                icon="edit"
                @click="
                  openEditCmsItemModal({
                    id,
                    title,
                    isActive,
                    type,
                    cmsMenuIdList,
                  })
                "
                class="cap-table-tick-icon"
              ></fa>
              <fa
                icon="trash-can"
                @click="openRemoveCmsItemModal({ id, title })"
                class="cap-table-cross-icon cap-table-action-icon"
              ></fa>
              <fa
                icon="align-justify"
                @click="rearrangeChildren({ id, title, items })"
                class="cap-table-bars"
                :class="{ 'cap-table-disabled-icon': items.length < 2 }"
              ></fa>
              <fa
                v-if="isOpen"
                icon="angle-up"
                @click="toggleItemTable({ id })"
                class="cap-table-angle-icon cap-table-action-icon"
              ></fa>
              <fa
                v-else
                icon="angle-down"
                @click="toggleItemTable({ id })"
                class="cap-table-tick-icon cap-table-angle-icon cap-table-action-icon"
              ></fa>
            </div>
          </td>
        </tr>
        <CMSItemTable
          :isOpen="isOpen"
          :items="items"
          :cmsMenuId="cmsMenuId"
          :parentId="id"
          :cmsMenuIdList="cmsMenuIdList"
          :cmsParentIdList="cmsParentIdList"
          :cmsPageIdList="cmsPageIdList"
          :cmsTypeList="cmsTypeList"
          :cmsTypeDictionary="cmsTypeDictionary"
          :cmsPageIdDictionary="cmsPageIdDictionary"
          @processCMSItemStateChange="processCMSItemStateChange"
        />
      </template>
    </template>
  </template>
</template>

<script>
import { computed, toRefs, watch, ref } from 'vue'
import { isNil, isEmpty, map, pipe, indexBy, prop, values } from 'ramda'

import ToggleButton from '@/components/ToggleButton'
import CMSItemTable from './CMSItemTable'

import {
  CMS_SUBLIST_TABLE,
  Module,
  SHOW_MODAL,
  EDIT_CMS_ITEM_MODAL,
  REMOVE_CMS_ITEM_MODAL,
  ResponseState,
  MessageType,
  REARRANGE_CMS_ITEM_MODAL,
} from '../../../../../constants'
import { useTranslate, useRouteQueryAction } from '../../../../../hooks'
import { commitMutation, load } from '../../../../../helpers'
import { CmsItemApi } from '../../../../../api'

export default {
  name: CMS_SUBLIST_TABLE,
  components: {
    ToggleButton,
    CMSItemTable,
  },
  props: {
    isOpen: Boolean,
    items: Array,
    parentId: Number,
    cmsMenuId: Number,
    cmsMenuIdList: Array,
    cmsParentIdList: Array,
    cmsPageIdList: Array,
    cmsTypeList: Array,
    cmsTypeDictionary: Object,
    cmsPageIdDictionary: Object,
  },
  setup(props, context) {
    const {
      items,
      cmsMenuId,
      parentId,
      cmsMenuIdList,
      cmsParentIdList,
      cmsPageIdList,
      cmsTypeList,
    } = toRefs(props)
    const list = ref(null)
    const t = useTranslate(CMS_SUBLIST_TABLE)
    const routeQueryAction = useRouteQueryAction()

    const hasNoList = computed(() => {
      return isEmpty(list.value) || isNil(list.value)
    })

    const renderedList = computed(() => {
      return pipe(values, indexBy(prop('position')))(list.value || {})
    })

    const toggleItemTable = ({ id }) => {
      list.value[id].isOpen = !list.value[id].isOpen
    }

    const processCMSItemStateChange = ({ message, messateType }) => {
      context.emit('processCMSItemStateChange', { message, messateType })
    }

    const openEditCmsItemModal = ({ id, title, isActive, type }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_CMS_ITEM_MODAL,
        isStrict: true,
        meta: {
          id,
          title,
          isActive,
          type,
          cmsMenuId: cmsMenuId.value,
          parentId: parentId.value,
          cmsMenuIdList: cmsMenuIdList.value,
          cmsParentIdList: cmsParentIdList.value,
          cmsPageIdList: cmsPageIdList.value,
          cmsTypeList: cmsTypeList.value,
        },
      })

      routeQueryAction.editItem()
    }

    const openRemoveCmsItemModal = ({ id, title }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_CMS_ITEM_MODAL,
        isStrict: true,
        meta: { id, title },
      })

      routeQueryAction.removeItem()
    }

    const rearrangeChildren = ({ id, title, items }) => {
      if (items.length < 2) {
        return
      }

      const childrenList = pipe(
        indexBy(prop('position')),
        map(({ id, title, position }) => ({
          itemId: id, //NOTE: this is real cms item id
          name: title,
          id: position, //NOTE: this id is needed for draggable component, i.e. position
        })),
        values,
      )(items)

      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REARRANGE_CMS_ITEM_MODAL,
        isStrict: true,
        meta: { id, title, childrenList },
      })

      routeQueryAction.rearrangeItems()
    }

    const changeActiveState = async ({ id, isActive }) => {
      const loaderList = {
        changeState: CmsItemApi.changeState({
          id,
          isActive: !isActive,
        }),
      }

      const { changeState } = await load(loaderList)
      const { state, msg } = changeState
      const messateType =
        state === ResponseState.OK ? MessageType.SUCCESS : MessageType.FAILURE

      context.emit('processCMSItemStateChange', { message: msg, messateType })
    }

    watch(
      items,
      (items) => {
        list.value = pipe(
          map((item) => {
            return { ...item, isOpen: false }
          }),
          indexBy(prop('id')),
        )(items || [])
      },
      {
        immediate: true,
      },
    )

    return {
      hasNoList,
      list,
      toggleItemTable,
      t,
      processCMSItemStateChange,
      openEditCmsItemModal,
      openRemoveCmsItemModal,
      changeActiveState,
      rearrangeChildren,
      renderedList,
    }
  },
}
</script>
