export const RoutePath = {
  CATEGORY: 'category',
  CMS: 'cms',
  CMS_PAGE: 'cms-page',
  GAME: 'game',
  LOCALE: '/:lang',
  LOGIN: 'login',
  PAGE_404: '/404',
  PANEL: 'panel',
  SLIDESHOW: 'slideshow',
  USER: 'user',
}
