<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('add_cms_item') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField :label="t('title')" name="title" :value="title" />
            <SelectField
              :label="t('type')"
              name="cmsType"
              :value="cmsType"
              :options="cmsTypeList"
            />
            <SelectField
              :label="t('parent')"
              name="parentId"
              :value="parentId"
              :options="cmsParentIdList"
            />
            <SelectField
              :label="t('cmsMenu')"
              name="cmsMenuId"
              :value="cmsMenuId"
              :options="cmsMenuIdList"
            />
            <TextField
              v-if="rendersRedirectUrl"
              :label="t('redirectUrl')"
              name="redirectUrl"
              :value="redirectUrl"
            />
            <SelectField
              v-if="rendersCmsPageId"
              :label="t('cmsPage')"
              name="cmsPageId"
              :value="cmsPageId"
              :options="cmsPageIdList"
            />
            <CheckboxField
              :label="t('active')"
              name="isActive"
              :value="isActive"
            />
            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('add') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useForm } from 'vee-validate'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'
import SelectField from '../components/SelectField.vue'
import TextField from '../components/TextField'
import CheckboxField from '../components/CheckboxField'

import {
  ADD_CMS_ITEM_MODAL,
  CMS_SUCCESS_MESSAGE,
  ResponseState,
} from '../constants'
import { CmsItemApi } from '../api'
import { load, makePositiveIntegerOrEmpty } from '../helpers'
import { useTranslate, useModal } from '../hooks'
import { validationSchema } from './EditCmsItem.vue'

const initialValues = {
  title: '',
  cmsMenuId: '',
  redirectUrl: '',
  parentId: '',
  cmsPageId: '',
  cmsType: '',
  isActive: false,
}

export default {
  name: ADD_CMS_ITEM_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextField,
    SelectField,
    CheckboxField,
  },
  setup(_, context) {
    const { message, closeModal, data } = useModal(context)
    const t = useTranslate(ADD_CMS_ITEM_MODAL)
    const { cmsMenuIdList, cmsParentIdList, cmsPageIdList, cmsTypeList } =
      data ?? {}

    const { meta, isSubmitting, handleSubmit, values } = useForm({
      initialValues,
      validationSchema,
    })

    const cmsTypeValue = computed(() => Number(values.cmsType))
    const rendersRedirectUrl = computed(
      () => cmsTypeValue.value === 1 || cmsTypeValue.value === 2,
    )
    const rendersCmsPageId = computed(() => cmsTypeValue.value === 3)

    const onSubmit = handleSubmit(
      async ({
        cmsMenuId,
        title,
        redirectUrl,
        parentId,
        cmsPageId,
        cmsType,
        isActive,
      }) => {
        const _cmsPageId = makePositiveIntegerOrEmpty(cmsPageId)
        const _parentId = makePositiveIntegerOrEmpty(parentId)
        const _cmsType = makePositiveIntegerOrEmpty(cmsType)
        const _cmsMenuId = makePositiveIntegerOrEmpty(cmsMenuId)

        const request = {
          cmsMenuId: _cmsMenuId,
          title,
          redirectUrl,
          parentId: _parentId,
          cmsPageId: _cmsPageId,
          type: _cmsType,
          isActive,
        }

        const { addCMSItem } = await load({
          addCMSItem: CmsItemApi.add(request),
        })
        const { msg, state } = addCMSItem

        if (state === ResponseState.ERROR) {
          message.value = msg
          return
        }

        message.value && (message.value = '')
        sessionStorage.setItem(CMS_SUCCESS_MESSAGE, msg)

        closeModal()
      },
    )

    return {
      cmsTypeList,
      message,
      closeModal,
      t,
      meta,
      isSubmitting,
      onSubmit,
      cmsMenuIdList,
      cmsParentIdList,
      cmsPageIdList,
      rendersRedirectUrl,
      rendersCmsPageId,
      ...initialValues,
    }
  },
}
</script>
