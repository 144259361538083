<template>
  <div :class="modalBackground" @click="handleClick" />
</template>
<script>
export default {
  name: 'MODAL_BACKGROUND',
  props: {
    isActive: Boolean,
    closesModal: Boolean,
  },
  data() {
    return {
      modalHiding: null,
    }
  },
  computed: {
    modalBackground() {
      return this.isActive
        ? 'cap-modal-background'
        : 'cap-modal-background-hidden'
    },
  },
  methods: {
    handleClick() {
      if (!this.closesModal) {
        return
      }

      this.$emit('close')
    },
  },
}
</script>
