<template>
  <router-link :to="derivedTo" class="cap-route">
    <slot />
  </router-link>
</template>
<script>
import { computed } from 'vue'
import { is, isNil } from 'ramda'

export default {
  name: 'Route',
  props: {
    to: {
      default: '#',
      type: [String, Object],
    },
  },
  setup(props) {
    const derivedTo = computed(() => {
      if (is(String, props.to)) {
        return props.to
      }

      const derivedTo = { ...props.to }
      isNil(derivedTo.params) && (derivedTo.params = {})

      return derivedTo
    })

    return {
      derivedTo,
    }
  },
}
</script>
