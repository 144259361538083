<template>
  <div v-if="rendersMessage" :class="formMessageClass" @click="close">
    <div v-if="isMessageArray">
      <div v-for="(msgLine, index) in message" :key="index">
        {{ msgLine }}
      </div>
    </div>
    <div v-else>
      {{ message }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { MessageType, MESSAGE_COMPONENT } from '@/constants'

const CAP_MESSAGE_CLASS = 'cap-message'
const CAP_MESSAGE_BOTTOM_CLASS = 'cap-message-bottom'
const CAP_MESSAGE_TOP_CLASS = 'cap-message-top'

export default {
  name: MESSAGE_COMPONENT,
  props: {
    type: {
      type: String,
      default: MessageType.FAILURE,
      validator: (value) =>
        [MessageType.SUCCESS, MessageType.FAILURE, MessageType.NOTICE].includes(
          value,
        ),
    },
    message: {
      type: [Array, String],
      default: () => [],
    },
    hasBottom: {
      type: Boolean,
      default: false,
    },
    hasTop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const rendersMessage = computed(() => {
      return props.message && props.message.length
    })
    const formMessageClass = computed(() => {
      return [
        CAP_MESSAGE_CLASS,
        `${CAP_MESSAGE_CLASS}-${props.type}`,
        props.hasBottom ? CAP_MESSAGE_BOTTOM_CLASS : '',
        props.hasTop ? CAP_MESSAGE_TOP_CLASS : '',
      ]
    })
    const isMessageArray = computed(() => Array.isArray(props.message))

    const close = () => {
      context.emit('close')
    }

    return {
      rendersMessage,
      formMessageClass,
      isMessageArray,
      close,
    }
  },
}
</script>
