<template>
  <div class="cap-main-content">
    <Header />
    <router-view></router-view>
  </div>
</template>
<script>
import Header from '@/components/Header'

export default {
  name: 'MainContent',
  components: {
    Header,
  },
}
</script>
