<template>
  <div class="cap-login">
    <div class="cap-login-modal">
      <div class="cap-login-header">
        {{ t('login') }}
      </div>
      <Message :message="message" :type="messageType" :hasBottom="true" />
      <form @submit="onSubmit" class="cap-form-data">
        <div class="form-group cap-form-input-container cap-input">
          <label for="username">{{ t('login') }}</label>
          <Field
            id="username"
            name="username"
            type="username"
            class="form-control form-control"
            autocomplete="email login"
            @input="onInput"
            @blur="onBlur"
          />
          <ErrorMessage name="username" class="cap-error-message" />
        </div>

        <div class="form-group cap-form-input-container cap-input">
          <label for="password">{{ t('password') }}</label>
          <Field
            id="password"
            name="password"
            type="password"
            class="form-control form-control"
            autocomplete="current-password"
            @input="onInput"
            @blur="onBlur"
          />
          <ErrorMessage name="password" class="cap-error-message" />
        </div>
        <div class="cap-login-button-container">
          <Button
            :disabled="isSubmitting || !meta.dirty || pristine"
            :rendersLoader="isSubmitting"
            class="cap-login-button"
          >
            <span>{{ t('submit') }}</span>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { Field, ErrorMessage, useForm } from 'vee-validate'
import * as yup from 'yup'

import Message from '../../components/Message.vue'
import Button from '../../components/Button.vue'

import router from '@/router'
import {
  RouteName,
  LOGIN,
  Module,
  LOG_IN,
  ResponseState,
  MessageType,
} from '@/constants'
import { useTranslate, useActions } from '@/hooks'

const messageType = MessageType.FAILURE
const initialValues = {
  username: '',
  password: '',
}
const validationSchema = yup.object({
  username: yup
    .string()
    .required('Login is required.')
    .email('Login must be proper email address.')
    .max(255, 'Login cannot be more than 255 characters'),
  password: yup
    .string()
    .required('Password is required.')
    .min(4, 'Password must have at least 4 characters.')
    .max(255, 'Password cannot be more than 255 characters'),
})

export default {
  name: LOGIN,
  components: {
    Field,
    ErrorMessage,
    Message,
    Button,
  },
  setup() {
    const t = useTranslate(LOGIN)
    const message = ref('')
    const isSubmitFailed = ref(false)
    const [login] = useActions([Module.AUTH, LOG_IN])
    const pristine = ref(true)
    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues,
      validationSchema,
    })

    const onInput = () => {
      if (!isSubmitFailed.value) return

      message.value = ''
      isSubmitFailed.value = false
    }
    const onSubmit = handleSubmit(async ({ username, password }) => {
      const { msg, state } = await login({
        username,
        password,
      })

      if (state === ResponseState.ERROR) {
        message.value = msg
        isSubmitFailed.value = true
        return
      }

      router.push({ name: RouteName.PANEL })
    })

    const onBlur = () => {
      pristine.value && (pristine.value = false)
    }

    return {
      message,
      messageType,
      t,
      meta,
      isSubmitting,
      pristine,
      onSubmit,
      onInput,
      onBlur,
    }
  },
}
</script>
