<template>
  <div
    class="cap-panel-menu"
    :class="{ 'cap-panel-menu--closed': !isPanelOpen }"
  >
    <div class="cap-panel-list">
      <button @click="togglePanel" class="cap-panel-menu-arrow-btn">
        <fa
          icon="arrow-left"
          class="cap-panel-menu-item-icon cap-panel-menu-item-icon-left"
        ></fa>
        <fa
          icon="arrow-right"
          class="cap-panel-menu-item-icon cap-panel-menu-item-icon-right"
        ></fa>
      </button>
      <Route
        v-for="{ name, label, iconId } in routeDictionary"
        :key="name"
        :to="{ name }"
        class="cap-panel-menu-item-container"
        :class="{
          'cap-panel-menu-item-container--selected': currentRouteName === name,
          'cap-panel-menu-item-container--closed': !isPanelOpen,
        }"
      >
        <fa
          :icon="iconDictionary[iconId]"
          class="cap-panel-menu-item-icon"
        ></fa>
        <span class="cap-panel-menu-item">
          {{ label }}
        </span>
      </Route>
    </div>
    <div
      class="cap-panel-sublist"
      :class="{ 'cap-panel-sublist--hidden': isSublistEmpty }"
    >
      <Route
        v-if="!isSublistEmpty"
        :to="sublistHeaderRoute"
        class="cap-panel-menu-item-container cap-panel-menu-item-container--selected"
        :class="{
          'cap-panel-menu-item-container--closed': !isPanelOpen,
        }"
      >
        <fa
          :icon="iconDictionary?.[sublistHeader.iconId]"
          class="cap-panel-menu-item-icon"
        ></fa>
        <span class="cap-panel-menu-item">{{ sublistHeader.label }}:</span>
      </Route>

      <Route
        v-for="{ id, name, image } in sublist"
        :key="id"
        class="cap-panel-menu-item-container"
        :class="{
          'cap-panel-menu-item-container--selected': currentSublistId === id,
          'cap-panel-menu-item-container--closed': !isPanelOpen,
        }"
        :to="{ name: currentRouteName, query: { id } }"
      >
        <div class="cap-panel-sublist-item-image-container">
          <img :src="image" class="cap-panel-sublist-item-image" />
        </div>
        <span class="cap-panel-menu-item">
          {{ name }}
        </span>
      </Route>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue'
import { isEmpty, when, pipe, path, indexBy, prop } from 'ramda'

import Route from './Route'

import {
  RouteName,
  Module,
  CATEGORY_DICTIONARY,
  PANEL_MENU,
} from '../constants'
import { getState } from '../helpers'
import { useTranslate, useCurrentRoute } from '../hooks'

const IconKey = {
  CATEGORY: 'CATEGORY',
  CMS: 'CMS',
  CMS_PAGE: 'CMS_PAGE',
  GAME: 'GAME',
  SLIDESHOW: 'SLIDESHOW',
  USER: 'USER',
}
const iconDictionary = {
  [IconKey.CATEGORY]: 'window-restore',
  [IconKey.CMS]: 'align-justify',
  [IconKey.CMS_PAGE]: 'file',
  [IconKey.GAME]: 'chess-knight',
  [IconKey.SLIDESHOW]: 'film',
  [IconKey.USER]: 'users',
}

const getSublist = ({ currentRouteName, categoryDictionary }) => {
  if (currentRouteName === RouteName.CATEGORY) {
    return categoryDictionary
  }

  return []
}
const getSublistHeader = ({ currentRouteName, routeDictionary }) => {
  if (currentRouteName === RouteName.CATEGORY) {
    return routeDictionary[currentRouteName]
  }

  return []
}

export default {
  name: PANEL_MENU,
  components: {
    Route,
  },
  setup() {
    const t = useTranslate(PANEL_MENU)
    const isPanelOpen = ref(true)
    const routeList = computed(() => [
      {
        name: RouteName.CATEGORY,
        label: t('categories'),
        iconId: IconKey.CATEGORY,
      },
      {
        name: RouteName.CMS,
        label: t('cms'),
        iconId: IconKey.CMS,
      },
      {
        name: RouteName.CMS_PAGE,
        label: t('cms_page'),
        iconId: IconKey.CMS_PAGE,
      },
      {
        name: RouteName.GAME,
        label: t('games'),
        iconId: IconKey.GAME,
      },
      {
        name: RouteName.SLIDESHOW,
        label: t('slideshow'),
        iconId: IconKey.SLIDESHOW,
      },
      { name: RouteName.USER, label: t('users'), iconId: IconKey.USER },
    ])

    const routeDictionary = computed(() => {
      return indexBy(prop('name'), routeList.value) || {}
    })

    const currentRoute = useCurrentRoute()
    const currentRouteName = computed(() => currentRoute.value.name)
    const currentSublistId = computed(() =>
      pipe(
        path(['value', 'query', 'id']),
        when(isFinite, Number),
      )(currentRoute),
    )
    const categoryDictionary = computed(() =>
      getState(Module.CATEGORY, CATEGORY_DICTIONARY),
    )
    const sublist = computed(() =>
      getSublist({
        currentRouteName: currentRouteName.value,
        categoryDictionary: categoryDictionary.value,
      }),
    )
    const isSublistEmpty = computed(() => isEmpty(sublist.value))
    const sublistHeader = computed(() =>
      getSublistHeader({
        currentRouteName: currentRouteName.value,
        routeDictionary: routeDictionary.value,
      }),
    )
    const sublistHeaderRoute = computed(() => ({
      name: currentRouteName.value,
    }))

    const togglePanel = () => (isPanelOpen.value = !isPanelOpen.value)

    return {
      routeDictionary,
      currentRouteName,
      currentSublistId,
      iconDictionary,
      isPanelOpen,
      togglePanel,
      isSublistEmpty,
      sublist,
      sublistHeader,
      sublistHeaderRoute,
    }
  },
}
</script>
