<template>
  <ThemeProvider class="cap-app">
    <GlobalLoader />
    <Modal />
    <router-view></router-view>
  </ThemeProvider>
</template>

<script>
import GlobalLoader from './components/GlobalLoader'
import Modal from './components/Modal'
import ThemeProvider from './components/ThemeProvider'

import { APP } from './constants'

/**
 * BUGS to investigate:
 * - side panel does not switch translation on language change
 * - on view load after login, it renders success message
 * - switching between the categories, the game list does not change
 */

export default {
  name: APP,
  components: {
    GlobalLoader,
    Modal,
    ThemeProvider,
  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
}
</style>
