/* eslint-disable no-unused-vars */
import { is, isNil } from 'ramda'
import { load } from '../helpers'

export class BaseResolver {
  static async loadToMeta({ list, to } = {}) {
    if (!is(Object, list) || isNil(to)) {
      console.error('inner: resolver loader got wrong data')
      return
    }

    to.meta.data = await load(list)
  }
}
