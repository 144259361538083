<template>
  <div class="cap-lang">
    <button @click="toggleLangList" class="cap-lang-btn">
      {{ currentLang }}
    </button>

    <div
      class="cap-lang-select"
      :class="{ 'cap-lang-select--displayed': isLangListOpen }"
    >
      <span
        v-for="lang in languageList"
        :key="lang"
        @click="selectLang(lang)"
        class="cap-lang-select-item"
        :class="{ 'cap-lang-select-item--selected': currentLang === lang }"
      >
        {{ lang }}
      </span>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { i18n } from '@/i18n'
import { useCurrentRoute } from '@/hooks'

export default {
  name: 'LanguageSelect',
  setup() {
    const languageList = process.env.VUE_APP_SUPPORTED_LOCALE.split(',')
    const router = useRouter()
    const isLangListOpen = ref(false)
    const currentRoute = useCurrentRoute()

    const currentLang = computed(() => i18n.global.locale.value)

    const changeLocale = (lang) => {
      i18n.global.locale.value = lang

      const nextPath = {
        name: currentRoute.value.name,
        query: { ...currentRoute.value.query },
        params: { ...currentRoute.value.params, lang },
      }

      router.push(nextPath)
    }

    const toggleLangList = () => {
      isLangListOpen.value = !isLangListOpen.value
    }

    const selectLang = (lang) => {
      toggleLangList()
      changeLocale(lang)
    }

    return {
      languageList,
      changeLocale,
      currentLang,
      toggleLangList,
      isLangListOpen,
      selectLang,
    }
  },
}
</script>
