import { createStore } from 'vuex'
import { auth, category, main, modal, slideshow } from './modules'
import { Module } from '@/constants'

const monitorMutations = (store) => {
  store.subscribe((mutation) => {
    console.log(mutation.type, mutation.payload)
  })
}

export const store = createStore({
  modules: {
    [Module.AUTH]: auth,
    [Module.CATEGORY]: category,
    [Module.MAIN]: main,
    [Module.MODAL]: modal,
    [Module.SLIDESHOW]: slideshow,
  },
  plugins: [monitorMutations],
})
