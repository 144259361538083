<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isPending }"
          :disabled="isPending"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">{{ t('remove_cms_title', { id }) }}</h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <span class="modal-question">
            {{ t('text', { name }) }}
          </span>

          <div class="modal-button-group">
            <Button
              buttonType="accent"
              :disabled="isPending"
              :rendersLoader="isSubmitting"
              @click="removeCmsPage"
            >
              <span>{{ t('remove') }}</span>
            </Button>
            <Button
              buttonType="secondary"
              :disabled="isPending"
              @click="closeModal"
            >
              {{ t('cancel') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'

import {
  REMOVE_CMS_MENU_MODAL,
  CMS_SUCCESS_MESSAGE,
  ResponseState,
} from '../constants'
import { useModal, useTranslate } from '../hooks'
import { load } from '../helpers'
import { CmsApi } from '../api'

export default {
  name: REMOVE_CMS_MENU_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
  },
  setup(props, context) {
    const t = useTranslate(REMOVE_CMS_MENU_MODAL)
    const { message, isPending, data, closeModal } = useModal(context)
    const { id, name } = data ?? {}

    const removeCmsPage = async () => {
      isPending.value = true
      const loaderList = {
        removeCmsMenu: CmsApi.remove({ id }),
      }

      const { removeCmsMenu } = await load(loaderList)
      const { state, msg } = removeCmsMenu

      if (state === ResponseState.ERROR) {
        message.value = msg
        isPending.value = false

        return
      }

      sessionStorage.setItem(CMS_SUCCESS_MESSAGE, msg)
      isPending.value = false
      closeModal()
    }

    return {
      id,
      message,
      name,
      isPending,
      t,
      closeModal,
      removeCmsPage,
    }
  },
}
</script>
