<template>
  <div class="cap-user">
    <div class="cap-panel-view-header">{{ t('all_users') }}</div>
    <div>
      <button class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_new_user') }}</span>
      </button>
    </div>
    <Message
      :message="editUserMessage"
      :type="MessageType.SUCCESS"
      :hasBottom="true"
      @close="closeEditUserMessage"
    />
    <div v-if="isNilList">
      <Loading />
    </div>
    <Message
      v-else-if="updateListMessage"
      :message="updateListMessage"
      :hasBottom="true"
    />
    <div v-else-if="isEmptyList">
      {{ t('no_user') }}
    </div>
    <table v-else class="cap-table">
      <tr class="cap-table-head">
        <th class="cap-table-cell-small">{{ t('id') }}</th>
        <th class="cap-table-cell">{{ t('email') }}</th>
        <th class="cap-table-cell">{{ t('username') }}</th>
        <th class="cap-table-cell">{{ t('created') }}</th>
        <th class="cap-table-cell-small">{{ t('isActive') }}</th>
        <th class="cap-table-cell-small">{{ t('actions') }}</th>
      </tr>
      <tr
        v-for="{ id, email, username, created, isActive } in list"
        class="cap-table-row"
        :key="id"
      >
        <th class="cap-table-cell-small">{{ id }}</th>
        <th class="cap-table-cell">{{ email }}</th>
        <th class="cap-table-cell">{{ username }}</th>
        <th class="cap-table-cell">{{ created }}</th>
        <th class="cap-table-cell-small">
          <fa v-if="isActive" icon="check" class="cap-table-tick-icon"></fa>
          <fa v-else icon="remove" class="cap-table-cross-icon"></fa>
        </th>
        <th class="cap-table-cell-small cap-table-btn-group">
          <fa
            @click="openEditUserModal({ id, username, isActive })"
            icon="edit"
            class="cap-table-tick-icon cap-table-action-icon"
          ></fa>
          <fa
            @click="openRemoveUserModal({ id, username })"
            icon="trash-can"
            class="cap-table-cross-icon cap-table-action-icon"
          ></fa>
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref, computed, watch, onMounted } from 'vue'
import { isNil, isEmpty } from 'ramda'

import Message from '@/components/Message'
import Loading from '@/components/Loading'

import {
  USER,
  ResponseState,
  SHOW_MODAL,
  Module,
  EDIT_USER_MODAL,
  REMOVE_USER_MODAL,
  RouteQueryKey,
  MessageType,
  USER_SUCCESS_MESSAGE,
} from '../../../../constants'
import { useTranslate, useCurrentRoute, useRouteQueryAction } from '@/hooks'
import { UserApi } from '@/api'
import { load, commitMutation } from '@/helpers'

export default {
  name: USER,
  components: {
    Message,
    Loading,
  },
  setup() {
    const t = useTranslate(USER)
    const list = ref(null)
    const updateListMessage = ref('')
    const editUserMessage = ref('')
    const currentRoute = useCurrentRoute()
    const routeQueryAction = useRouteQueryAction()

    const isNilList = computed(() => isNil(list.value))
    const isEmptyList = computed(() => isEmpty(list.value))
    const queryParams = computed(() => currentRoute.value.query)

    const openEditUserModal = ({ id, username, isActive }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_USER_MODAL,
        isStrict: true,
        meta: { id, username, isActive },
      })

      routeQueryAction.edit()
    }

    const openRemoveUserModal = ({ id, username }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_USER_MODAL,
        isStrict: true,
        meta: { id, username },
      })

      routeQueryAction.remove()
    }

    const updateList = async () => {
      const loaderList = {
        getList: UserApi.getAll(),
      }

      const { getList } = await load(loaderList)
      const { data, state, msg } = getList
      if (state === ResponseState.ERROR) {
        updateListMessage.value = msg
        return
      }

      list.value = data?.items
    }

    const closeEditUserMessage = () => {
      editUserMessage.value = ''
    }

    watch(
      queryParams,
      (state) => {
        if (state[RouteQueryKey.ACTION]) {
          editUserMessage.value && (editUserMessage.value = '')
          return
        }

        const newEditUserMessage = sessionStorage.getItem(USER_SUCCESS_MESSAGE)

        if (newEditUserMessage) {
          editUserMessage.value = newEditUserMessage
          sessionStorage.removeItem(USER_SUCCESS_MESSAGE)
          updateList()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    onMounted(updateList)

    return {
      t,
      list,
      isNilList,
      isEmptyList,
      MessageType,
      editUserMessage,
      closeEditUserMessage,
      openEditUserModal,
      updateListMessage,
      openRemoveUserModal,
    }
  },
}
</script>
