<template>
  <div>
    <div class="cap-panel-view-header">{{ t('slideshow') }}</div>

    <div>
      <button @click="openAddSlideModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_new_slide') }}</span>
      </button>
    </div>
    <Message
      :message="editSliderMessage"
      :type="MessageType.SUCCESS"
      :hasBottom="true"
      @close="closeEditUserMessage"
    />
    <div v-if="isNilList">
      <Loading />
    </div>
    <Message
      v-else-if="updateListMessage"
      :message="updateListMessage"
      :hasBottom="true"
    />
    <div v-else-if="isEmptyList">{{ t('no_slide') }}</div>
    <table v-else class="cap-table">
      <tr class="cap-table-head">
        <th class="cap-table-cell">{{ t('style') }}</th>
        <th class="cap-table-cell-small">{{ t('actions') }}</th>
      </tr>

      <tr v-for="(slide, id) in list" :key="id" class="cap-table-row-high">
        <th class="cap-table-cell">
          <div class="cap-table-slideshow-style" :style="slide.style"></div>
          <fa
            @click="copyToClipboard(slide.style)"
            icon="copy"
            class="cap-table-url-copy-icon"
          ></fa>
        </th>
        <th class="cap-table-cell-small">
          <!-- <fa icon="edit" class="cap-table-tick-icon"></fa> -->
          <fa
            icon="trash-can"
            @click="openRemoveSlideModal"
            class="cap-table-cross-icon cap-table-action-icon"
          ></fa>
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import { computed, watch, onMounted, ref } from 'vue'
import { isNil, isEmpty } from 'ramda'

import Loading from '@/components/Loading'
import Message from '@/components/Message'

import {
  Module,
  SLIDESHOW,
  ADD_SLIDESHOW_MODAL,
  REMOVE_SLIDESHOW_MODAL,
  SHOW_MODAL,
  ResponseState,
  MessageType,
  RouteQueryKey,
  SLIDE_SUCCESS_MESSAGE,
} from '../../../../constants'
import { copyToClipboard, commitMutation, load } from '../../../../helpers'
import {
  useTranslate,
  useCurrentRoute,
  useRouteQueryAction,
} from '../../../../hooks'
import { SlideshowApi } from '../../../../api'

//TODO: improve copy slide url logic

export default {
  name: SLIDESHOW,
  components: {
    Message,
    Loading,
  },
  setup() {
    const t = useTranslate(SLIDESHOW)
    const list = ref(null)
    const editSliderMessage = ref('')
    const updateListMessage = ref('')
    const currentRoute = useCurrentRoute()
    const routeQueryAction = useRouteQueryAction()

    const isNilList = computed(() => isNil(list.value))
    const isEmptyList = computed(() => isEmpty(list.value))
    const queryParams = computed(() => currentRoute.value.query)

    const openAddSlideModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_SLIDESHOW_MODAL,
        isStrict: true,
      })

      routeQueryAction.add()
    }

    const openRemoveSlideModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_SLIDESHOW_MODAL,
        isStrict: true,
      })

      routeQueryAction.remove()
    }

    const updateList = async () => {
      const loaderList = {
        getList: SlideshowApi.getAll(),
      }

      try {
        const { getList } = await load(loaderList)
        const { data, state, msg } = getList
        if (state === ResponseState.ERROR) {
          updateListMessage.value = msg
          return
        }

        list.value = data
      } catch (e) {
        console.error(e)
      }
    }

    const closeEditUserMessage = () => {
      editSliderMessage.value = ''
    }

    watch(
      () => queryParams,
      (state) => {
        if (state[RouteQueryKey.ACTION]) {
          editSliderMessage.value && (editSliderMessage.value = '')
          return
        }

        const newEditSlideMessage = sessionStorage.getItem(
          SLIDE_SUCCESS_MESSAGE,
        )

        if (newEditSlideMessage) {
          editSliderMessage.value = newEditSlideMessage
          sessionStorage.removeItem(SLIDE_SUCCESS_MESSAGE)
          updateList()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    onMounted(updateList)

    return {
      copyToClipboard,
      t,
      openAddSlideModal,
      openRemoveSlideModal,
      closeEditUserMessage,
      errorMessageType: MessageType.FAILURE,
      isNilList,
      isEmptyList,
      editSliderMessage,
      list,
      MessageType,
    }
  },
}
</script>
