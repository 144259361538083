<template>
  <div class="">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :disabled="isPending"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('add_slide') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message
            :message="message"
            :hasBottom="true"
            :type="currentMessageType"
          />
          <FileField v-model:value="fileValue" />

          <div class="modal-button-group">
            <Button
              buttonType="primary"
              :disabled="isPending || !fileValue"
              @click="submitSlide"
            >
              {{ t('add') }}
            </Button>
            <Button
              buttonType="secondary"
              :disabled="isPending"
              @click="closeModal"
            >
              {{ t('cancel') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import FileField from '../components/FileField.vue'
import Message from '../components/Message.vue'

import { ADD_SLIDESHOW_MODAL } from '../constants/modalName'
import { MessageType, ResponseState, SLIDE_SUCCESS_MESSAGE } from '../constants'
import { SlideshowApi } from '../api'
import { useTranslate, useModal } from '../hooks'
import { load } from '@/helpers'

//TODO: investigate bug file input. if you drop the file, you can't upload it back

export default {
  name: ADD_SLIDESHOW_MODAL,
  components: {
    Button,
    CloseIcon,
    FileField,
    Message,
  },
  setup(props, context) {
    const t = useTranslate(ADD_SLIDESHOW_MODAL)
    const { message, currentMessageType, isPending, closeModal } = useModal(
      context,
    )
    const fileValue = ref('')

    const submitSlide = async () => {
      if (!fileValue.value) {
        message.value = t('choose_file_first')
        currentMessageType.value = MessageType.FAILURE

        return
      }

      isPending.value = true

      try {
        const request = {
          image: fileValue.value.imagePath,
        }
        const loaderList = {
          addSlide: SlideshowApi.add(request),
        }
        const { addSlide } = await load(loaderList)
        const { msg, state } = addSlide

        if (state === ResponseState.ERROR) {
          currentMessageType.value = MessageType.FAILURE
          message.value = msg
          return
        }

        fileValue.value = ''
        message.value && (message.value = '')
        sessionStorage.setItem(SLIDE_SUCCESS_MESSAGE, msg)
      } catch (e) {
        console.log(e)
      }

      isPending.value = false
      closeModal()
    }

    watch(fileValue, (state, prevState) => {
      const dropsState = prevState && !state
      const getsImagePath = !prevState && state.imagePath

      if (dropsState || getsImagePath) {
        message.value = ''
      }
    })

    return {
      fileValue,
      currentMessageType,
      message,
      closeModal,
      submitSlide,
      t,
      isPending,
    }
  },
}
</script>
