import { indexBy, prop } from 'ramda'

import {
  CATEGORY_DICTIONARY,
  UPDATE_CATEGORY_DICTIONARY,
} from '../../constants'
import { CategoryApi } from '../../api'

export const category = {
  namespaced: true,
  state: () => ({
    [CATEGORY_DICTIONARY]: null,
  }),
  mutations: {
    [UPDATE_CATEGORY_DICTIONARY](state, value) {
      state[CATEGORY_DICTIONARY] = value
    },
  },
  actions: {
    async [UPDATE_CATEGORY_DICTIONARY]({ commit }) {
      const config = {
        //TODO: refactor
        data: null,
        error: null,
        msg: '',
        state: null,
      }

      try {
        const { data, state, msg } = await CategoryApi.getAll()
        config.data = indexBy(prop('id'), data || [])
        config.state = state
        config.msg = msg
      } catch (e) {
        console.dir(e)
        config.error = e
      }

      commit(UPDATE_CATEGORY_DICTIONARY, config.data)
      return config
    },
  },
}
