const body = document.querySelectorAll('body')[0]
const hiddenInitialValue = false

export const setBodyOverflow = ({ hidden = hiddenInitialValue } = {}) => {
  const mainContent = document.querySelector('.cap-panel-main-content')

  body.style.overflow = hidden ? 'hidden' : 'auto'

  if (mainContent) {
    mainContent.style.overflow = hidden ? 'hidden' : 'auto'
  }
}
