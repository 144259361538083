<template>
  <div :class="containerClassList" :name="name">
    <Message :message="message" :hasBottom="true" :type="currentMessageType" />

    <input
      :id="id"
      ref="inputElement"
      :class="inputClassList"
      type="file"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :name="name"
      @change="handleFileChange"
      accept="application/pdf, image/jpeg, image/png, image/gif"
      style="display: none;"
    />
    <div v-if="innerValue" class="cap-file-field-file-container">
      <span
        class="cap-file-field-file-name"
        data-toggle="tooltip"
        data-placement="bottom"
        :title="fileName"
      >
        {{ fileName }}
      </span>
      <button
        class="cap-file-field-reset-button"
        @click="handleResetButtonClick"
      >
        <CloseIcon />
      </button>
    </div>
    <button v-else :class="buttonClassList" @click="handleButtonClick">
      <ButtonLoader v-if="isLoading" />
      <span v-else>{{ label || t('choose_file') }}</span>
    </button>
  </div>
</template>

<script>
import { ref, computed, watch, toRefs } from 'vue'
import { isNil, head } from 'ramda'

import ButtonLoader from './ButtonLoader.vue'
import CloseIcon from './CloseIcon.vue'
import Message from './Message.vue'

import { FILE_FIELD, INPUT_CONTAINER_CLASS, MessageType } from '@/constants'
import { generateUUID } from '@/helpers'
import { useTranslate } from '@/hooks'

const CAP_FILE_FIELD = 'cap-file-field'
const BUTTON_CLASS_LIST = 'cap-button cap-button-primary cap-file-field-button'
const FORM_CONTROL = 'form-control'

export default {
  name: FILE_FIELD,
  inheritAttrs: false,
  components: {
    ButtonLoader,
    CloseIcon,
    Message,
  },
  props: {
    readonly: Boolean,
    buttonClass: String,
    placeholder: String,
    disabled: Boolean,
    name: String,
    label: String,
    serverError: String,
    validators: {
      type: Array,
      default: () => [],
    },
    containerClass: String,
    inputClass: String,
    value: {
      type: [String, Object],
      default: '',
    },
  },
  setup(props, { emit }) {
    const t = useTranslate(FILE_FIELD)
    const fileName = ref(null)
    const isLoading = ref(false)
    const innerValue = ref(null)
    const innerValidation = ref([])
    const isReady = ref(false)
    const currentMessageType = ref(MessageType.FAILURE)
    const inputElement = ref(null)
    const { value, serverError, validators } = toRefs(props)

    const id = computed(() => `${generateUUID()}_input`)
    const containerClassList = computed(() => [
      INPUT_CONTAINER_CLASS,
      CAP_FILE_FIELD,
      props.containerClass,
    ])
    const buttonClassList = computed(() => [
      BUTTON_CLASS_LIST,
      props.buttonClass,
    ])
    const inputClassList = computed(() => [FORM_CONTROL, props.inputClass])
    const messages = computed(() => {
      const array = []
      innerValidation.value.forEach(
        (item) => item.message && array.push(item.message),
      )

      return array
    })
    const message = computed(() => serverError || head(messages.value))

    const handleValidation = () => {
      innerValidation.value = []

      validators.value.forEach((invokeValidator) => {
        const result = invokeValidator(innerValue.value)
        const message = typeof result === 'string' ? result : null

        innerValidation.value.push({
          check: typeof result === 'boolean' && result,
          message,
        })
      })
    }
    const handleInput = (value) => {
      innerValue.value = value
      emit('update:value', value)

      if (isReady.value) {
        handleValidation()
      }
    }
    const makeReady = () => {
      if (!isReady.value) {
        isReady.value = true
      }
    }
    const handleButtonClick = () => {
      makeReady()
      inputElement.value.click()
    }
    const handleResetButtonClick = () => {
      fileName.value = null
      handleInput('')
    }
    const handleFileChange = (e) => {
      if (value.value) {
        handleResetButtonClick()
      }

      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const file = files[0]
      const _fileName = file.name
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => {
        fileName.value = _fileName

        handleInput({
          imagePath: reader.result.split(',')[1],
        })
      }
      reader.onerror = (error) => {
        console.log('Error: ', error)
      }
    }

    watch(value, (state, prevState) => {
      handleInput(state)

      if (!isNil(state) && isReady.value) {
        handleValidation()
      }
      if (prevState && !state) {
        handleResetButtonClick()
      }
    })
    watch(validators, () => {
      if (isReady.value) {
        handleValidation()
      }
    })

    return {
      fileName,
      isLoading,
      innerValue,
      innerValidation,
      isReady,
      id,
      containerClassList,
      buttonClassList,
      inputClassList,
      t,
      messages,
      message,
      currentMessageType,
      makeReady,
      handleValidation,
      handleInput,
      handleButtonClick,
      handleResetButtonClick,
      handleFileChange,
      inputElement,
    }
  },
}
</script>
