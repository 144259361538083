export const ADD_CATEGORY_MODAL = 'ADD_CATEGORY_MODAL'
export const EDIT_CATEGORY_MODAL = 'EDIT_CATEGORY_MODAL'
export const REMOVE_CATEGORY_MODAL = 'REMOVE_CATEGORY_MODAL'

export const ADD_CMS_ITEM_MODAL = 'ADD_CMS_ITEM_MODAL'
export const EDIT_CMS_ITEM_MODAL = 'EDIT_CMS_ITEM_MODAL'
export const REMOVE_CMS_ITEM_MODAL = 'REMOVE_CMS_ITEM_MODAL'
export const REARRANGE_CMS_ITEM_MODAL = 'REARRANGE_CMS_ITEM_MODAL'

export const ADD_CMS_MENU_MODAL = 'ADD_CMS_MENU_MODAL'
export const EDIT_CMS_MENU_MODAL = 'EDIT_CMS_MENU_MODAL'
export const REMOVE_CMS_MENU_MODAL = 'REMOVE_CMS_MENU_MODAL'

export const ADD_CMS_PAGE_MODAL = 'ADD_CMS_PAGE_MODAL'
export const EDIT_CMS_PAGE_MODAL = 'EDIT_CMS_PAGE_MODAL'
export const REMOVE_CMS_PAGE_MODAL = 'REMOVE_CMS_PAGE_MODAL'

export const ADD_GAME_MODAL = 'ADD_GAME_MODAL'
export const EDIT_GAME_MODAL = 'EDIT_GAME_MODAL'
export const REMOVE_GAME_MODAL = 'REMOVE_GAME_MODAL'

export const ADD_SLIDESHOW_MODAL = 'ADD_SLIDESHOW_MODAL'
export const REMOVE_SLIDESHOW_MODAL = 'REMOVE_SLIDESHOW_MODAL'

export const EDIT_USER_MODAL = 'EDIT_USER_MODAL'
export const REMOVE_USER_MODAL = 'REMOVE_USER_MODAL'
