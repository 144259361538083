<template>
  <button :disabled="disabled" class="cap-button" :class="buttonTypeClass">
    <ButtonLoader v-if="rendersLoader" />
    <span v-else><slot></slot></span>
  </button>
</template>
<script>
import { computed } from 'vue'

import ButtonLoader from '../components/ButtonLoader.vue'

import { BUTTON, ButtonType as BT } from '../constants'

/**
 * @param {'primary'|'secondary'|'accent'} buttonType affects background-color
 */

export default {
  name: BUTTON,
  components: {
    ButtonLoader,
  },
  props: {
    buttonType: {
      type: String,
      default: BT.PRIMARY,
      required: false,
      validator: (value) =>
        [BT.PRIMARY, BT.SECONDARY, BT.ACCENT].includes(value),
    },
    disabled: Boolean,
    rendersLoader: Boolean,
  },
  setup(props) {
    const buttonTypeClass = computed(() => [
      `cap-button-${props.buttonType || BT.PRIMARY}`,
      props.disabled ? `cap-button-disabled` : null,
    ])

    return {
      buttonTypeClass,
    }
  },
}
</script>
