<template>
  <div
    class="cap-toggle"
    :class="{ 'cap-toggle-checked': value }"
    @click="$emit('toggle')"
  >
    <div class="cap-toggle-bar"></div>
    <svg height="30" width="30" class="cap-toggle-circle">
      <circle cx="15" cy="15" r="14.5" stroke-width="1" />
    </svg>
  </div>
</template>

<script>
import { TOGGLE_BUTTON } from '@/constants'

export default {
  name: TOGGLE_BUTTON,
  inheritAttrs: false,
  props: {
    value: Boolean,
  },
}
</script>
