export const RouteName = {
  CATEGORY: 'CATEGORY',
  CMS: 'CMS',
  CMS_PAGE: 'CMS_PAGE',
  GAME: 'GAME',
  LOCALE: 'LOCALE',
  LOGIN: 'LOGIN',
  PAGE_404: 'Page404',
  PANEL: 'PANEL',
  SLIDESHOW: 'SLIDESHOW',
  USER: 'USER',
}
