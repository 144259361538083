import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { isEmpty, isNil } from 'ramda'

import { ResponseState, MessageType } from '../constants'
import { callFn, load } from '../helpers'
import { useCurrentRoute } from './useCurrentRoute'

export const usePagination = ({ apiMethod, ...props } = {}) => {
  const router = useRouter()
  const currentRoute = useCurrentRoute()
  const list = ref(null)
  const pagination = ref({
    page: currentRoute.value.query.page || 1,
    limit: currentRoute.value.query.limit || 5,
    total: 0,
  })
  const isPendingList = ref(false)
  const message = ref('')
  const messageType = MessageType.FAILURE

  const isEmptyList = computed(() => isEmpty(list.value))
  const isNilList = computed(() => isNil(list.value))
  const queryParams = computed(() => currentRoute.value.query)

  const updateQueryParams = () => {
    router.push({
      name: currentRoute.value.name,
      query: {
        ...queryParams.value,
        page: pagination.value.page,
        limit: pagination.value.limit,
        ...props,
      },
    })
  }

  const updatePagination = (newValue) => {
    pagination.value = newValue

    updateQueryParams()
  }

  const updateList = async (args = {}) => {
    isPendingList.value = true

    const loaderList = {
      getList: callFn(apiMethod, {
        page: pagination.value.page,
        limit: pagination.value.limit,
        ...args,
        ...props,
      }),
    }

    try {
      const { getList } = await load(loaderList)
      const { state, data, msg } = getList

      if (state === ResponseState.OK) {
        list.value = data?.items
        pagination.value = {
          page: pagination.value.page,
          limit: data?.itemsPerPage,
          total: data?.total,
        }
      }

      if (ResponseState.ERROR === state && msg) {
        message.value = msg
      }
    } catch (error) {
      console.dir(error)
    }

    isPendingList.value = false
  }

  watch(() => queryParams.value, updateList, { immediate: true, deep: true })

  return {
    list,
    pagination,
    isPendingList,
    isEmptyList,
    isNilList,
    messageType,
    updatePagination,
    updateQueryParams,
  }
}
