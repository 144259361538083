<template>
  <div class="form-check cap-form-checkbox-container cap-input">
    <Field
      :id="name"
      :name="name"
      type="checkbox"
      class="form-control form-checkbox"
      :v-model="value"
      :value="true"
      :unchecked-value="false"
    />
    <label :for="name" class="form-check-label">
      {{ label }}
    </label>
  </div>
  <ErrorMessage :name="name" class="cap-error-message" />
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'

import { CHECKBOX_FIELD } from '../constants'

export default {
  name: CHECKBOX_FIELD,
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    label: String,
    name: String,
    value: Boolean,
  },
}
</script>
