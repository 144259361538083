<template>
  <div class="cap-lang cap-theme" v-click-outside="closeThemeSelect">
    <button @click="toggleThemeList" class="cap-lang-btn cap-theme-btn">
      {{ currentThemeName }}
    </button>

    <div
      class="cap-lang-select cap-theme-select"
      :class="{ 'cap-lang-select--displayed': isThemeListOpen }"
    >
      <span
        v-for="{ option, name } in themeList"
        :key="option"
        @click="selectTheme(option)"
        class="cap-lang-select-item"
        :class="{ 'cap-lang-select-item--selected': currentTheme === option }"
      >
        {{ name }}
      </span>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { map, toLower, replace, pipe, indexBy, prop } from 'ramda'

import {
  THEME_SELECT,
  SET_CURRENT_THEME,
  Module,
  CURRENT_THEME,
  STYLE_THEME,
} from '../constants'

import { useMutations, useState } from '../hooks'

const themeList = pipe(
  map((option) => {
    const name = pipe(toLower, replace('_', ' '))(option)

    return {
      option,
      name,
    }
  }),
  indexBy(prop('option')),
)(process.env.VUE_APP_THEME.split(',') || [])

export default {
  name: THEME_SELECT,
  setup() {
    const isThemeListOpen = ref(false)
    const [currentTheme] = useState([Module.MAIN, CURRENT_THEME])
    const currentThemeName = computed(() => themeList[currentTheme.value].name)

    const [setTheme] = useMutations([Module.MAIN, SET_CURRENT_THEME])

    const toggleThemeList = () => {
      isThemeListOpen.value = !isThemeListOpen.value
    }

    const selectTheme = (option) => {
      setTheme(option)
      localStorage.setItem(STYLE_THEME, option)
      toggleThemeList()
    }

    const closeThemeSelect = () => {
      isThemeListOpen.value && toggleThemeList()
    }

    return {
      themeList,
      currentTheme,
      isThemeListOpen,
      currentThemeName,
      toggleThemeList,
      selectTheme,
      closeThemeSelect,
    }
  },
}
</script>
