import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class CmsApi extends BaseApi {
  static add(data) {
    return super.axios.post(Endpoint.API_ADMIN_CMS_MENU_ADD, data)
  }
  static edit(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CMS_MENU_EDIT, data)
  }
  static getAll() {
    return super.axios.get(Endpoint.API_ADMIN_CMS_TREE)
  }
  static remove({ id }) {
    return super.axios.delete(
      super.formatUrl(Endpoint.API_ADMIN_CMS_MENU_DELETE, { id }),
    )
  }
  static changeState(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CMS_MENU_STATE, data)
  }
}
