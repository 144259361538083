<template>
  <div class="cap-panel">
    <PanelMenu />
    <div class="cap-panel-main-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import PanelMenu from '../../components/PanelMenu'

import { PANEL } from '../../constants'

export default {
  name: PANEL,
  components: {
    PanelMenu,
  },
}
</script>
