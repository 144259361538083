<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('rearrange_cms_items', { name: name || title }) }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <Draggable class="cap-draggable" :list="childrenList">
            <div
              class="cap-draggable-item"
              v-for="element in childrenList"
              :key="element.name"
            >
              {{ element.name }}
            </div>
          </Draggable>
          <div class="modal-button-group">
            <Button
              :disabled="isPending"
              :rendersLoader="isPending"
              @click="onSubmit"
              buttonType="primary"
              type="submit"
            >
              <span>{{ t('save') }}</span>
            </Button>
            <Button
              :disabled="isPending"
              buttonType="secondary"
              type="button"
              @click="closeModal"
            >
              {{ t('cancel') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next'
import { pluck, pipe, values } from 'ramda'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'

import {
  REARRANGE_CMS_ITEM_MODAL,
  ResponseState,
  CMS_SUCCESS_MESSAGE,
} from '@/constants'
import { useModal, useTranslate } from '../hooks'
import { load } from '../helpers'
import { CmsItemApi } from '../api'

export default {
  name: REARRANGE_CMS_ITEM_MODAL,
  components: {
    CloseIcon,
    Button,
    Message,
    Draggable: VueDraggableNext,
  },
  setup(_, context) {
    const t = useTranslate(REARRANGE_CMS_ITEM_MODAL)
    const { message, closeModal, data, isPending } = useModal(context)
    const { id, name, title, childrenList } = data ?? {}

    const onSubmit = async () => {
      isPending.value = true

      const list = pipe(pluck('itemId'), values)(childrenList || {})
      const request = {
        position: list,
      }

      const loaderList = {
        changePosition: CmsItemApi.changePosition(request),
      }

      const { changePosition } = await load(loaderList)
      const { msg, state } = changePosition

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CMS_SUCCESS_MESSAGE, msg)
      isPending.value = false
      closeModal()
    }

    return {
      message,
      id,
      name,
      title,
      childrenList,
      isPending,
      onSubmit,
      closeModal,
      t,
    }
  },
}
</script>
