import {
  SLIDESHOW_DICTIONARY,
  UPDATE_SLIDESHOW_DICTIONARY,
} from '../../constants'
import { SlideshowApi } from '../../api'

export const slideshow = {
  namespaced: true,
  state: () => ({
    [SLIDESHOW_DICTIONARY]: null, //TODO: it is not dictionary but list
  }),
  mutations: {
    [UPDATE_SLIDESHOW_DICTIONARY](state, value) {
      state[SLIDESHOW_DICTIONARY] = value
    },
  },
  actions: {
    async [UPDATE_SLIDESHOW_DICTIONARY]({ commit }) {
      const config = {
        data: null,
        error: null,
        msg: '',
        state: null,
      }

      try {
        const { data, state, msg } = await SlideshowApi.getAll()
        config.data = data || []
        config.state = state
        config.msg = msg
      } catch (e) {
        console.dir(e)
        config.error = e
      }

      commit(UPDATE_SLIDESHOW_DICTIONARY, config.data)
      return config
    },
  },
}
