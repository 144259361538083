<template>
  <div class="cap-panel-view">
    <div class="cap-panel-view-header">{{ t('all_games') }}</div>
    <div>
      <button @click="openAddGameModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_new_game') }}</span>
      </button>
    </div>
    <div v-if="isPendingList"><Loading /></div>
    <div v-else-if="isEmptyList">{{ t('no_game') }}</div>
    <div v-else>
      <GameTable :list="list" />
      <Paginator
        :modelValue="pagination"
        @update:modelValue="updatePagination"
      />
    </div>
  </div>
</template>
<script>
import GameTable from '@/components/GameTable'
import Paginator from '@/components/Paginator'
import Loading from '@/components/Loading'

import { Module, SHOW_MODAL, GAME, ADD_GAME_MODAL } from '@/constants'
import { commitMutation, copyURL } from '@/helpers'
import { useTranslate, usePagination, useRouteQueryAction } from '@/hooks'
import { GameApi } from '@/api'

const apiMethod = GameApi.getAll

export default {
  name: GAME,
  components: {
    GameTable,
    Paginator,
    Loading,
  },
  setup() {
    const t = useTranslate(GAME)
    const routeQueryAction = useRouteQueryAction()

    const {
      list,
      pagination,
      isPendingList,
      isEmptyList,
      updatePagination,
    } = usePagination({
      apiMethod,
    })

    const openAddGameModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_GAME_MODAL,
        isStrict: true,
      })

      routeQueryAction.add()
    }

    return {
      list,
      pagination,
      copyURL,
      t,
      openAddGameModal,
      isPendingList,
      isEmptyList,
      updatePagination,
    }
  },
}
</script>
