export const APP = 'App'
export const BALLOON = 'Balloon'
export const BALLOON_ARROW = 'BaloonArrow'
export const BUTTON = 'Button'
export const BUTTON_LOADER = 'ButtonLoader'
export const CATEGORY = 'Category'
export const CATEGORY_GAME = 'CategoryGame'
export const CATEGORY_LIST = 'CategoryList'
export const CHECKBOX_FIELD = 'CheckboxField'
export const CMS_COMPONENT = 'CMS'
export const CMS_ITEM_TABLE = 'CMSItemTable'
export const CMS_SUBLIST_TABLE = 'CMSSublistTable'
export const CMS_PAGE_VIEW = 'CMSPageVIew'
export const CLOSE_ICON = 'CloseIcon'
export const FILE_FIELD = 'FileField'
export const GAME = 'Game'
export const GAME_TABLE = 'GameTable'
export const GLOBAL_LOADER = 'GlobalLoader'
export const HEADER = 'Header'
export const LOADER_COMPONENT = 'Loader'
export const LOADING_COMPONENT = 'Loading'
export const LOGIN = 'Login'
export const MESSAGE_COMPONENT = 'MessageComponent'
export const MODAL_COMPONENT = 'ModalComponent'
export const MODAL_CONTENT_COMPONENT = 'ModalContentComponent'
export const PAGINATION = 'Paginator'
export const PANEL = 'Panel'
export const PANEL_MENU = 'PanelMenu'
export const SELECT_FIELD = 'SelectField'
export const SLIDESHOW = 'Slideshow'
export const TEXT_EDITOR = 'TextEditor'
export const TEXT_FIELD = 'TextField'
export const TOGGLE_BUTTON = 'ToggleField'
export const THEME_PROVIDER = 'ThemeProvider'
export const THEME_SELECT = 'ThemeSelect'
export const USER = 'User'
