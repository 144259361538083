<template>
  <div :class="`theme theme--${currentTheme}`">
    <div :class="currentTheme">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from 'vue'

import {
  Module,
  CURRENT_THEME,
  THEME_PROVIDER,
  STYLE_THEME,
  SET_CURRENT_THEME,
} from '../constants'
import { useState, useMutations } from '../hooks'

const themeList = process.env.VUE_APP_THEME.split(',')

export default {
  name: THEME_PROVIDER,
  setup() {
    const [currentTheme] = useState([Module.MAIN, CURRENT_THEME])
    const savedTheme = localStorage.getItem(STYLE_THEME)

    const [setTheme] = useMutations([Module.MAIN, SET_CURRENT_THEME])

    const initializeTheme = () => {
      const isValidSavedTheme = themeList.includes(savedTheme)

      isValidSavedTheme && setTheme(savedTheme)
    }

    onBeforeMount(initializeTheme)

    return {
      currentTheme,
    }
  },
}
</script>
