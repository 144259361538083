import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class CmsItemApi extends BaseApi {
  static add(data) {
    return super.axios.post(Endpoint.API_ADMIN_CMS_ITEM_ADD, data)
  }
  static edit(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CMS_ITEM_EDIT, data)
  }
  static getAll() {
    return super.axios.get(Endpoint.API_ADMIN_CMS_TREE)
  }
  static remove({ id }) {
    return super.axios.delete(
      super.formatUrl(Endpoint.API_ADMIN_CMS_ITEM_DELETE, { id }),
    )
  }
  static changeState(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CMS_ITEM_STATE, data)
  }
  static changePosition(data) {
    return super.axios.patch(Endpoint.API_ADMIN_CMS_ITEM_POSITION, data)
  }
}
