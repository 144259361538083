<template>
  <div class="cap-header" :class="{ 'cap-header-login': isLoginPage }">
    <div class="cap-header-theme-select">
      <ThemeSelect />
    </div>
    <div class="cap-header-lang-select">
      <LanguageSelect />
    </div>
    <div v-if="!isLoginPage" @click="logout" class="cap-header-logout">
      {{ t('logout') }}
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import LanguageSelect from './LanguageSelect'
import ThemeSelect from './ThemeSelect'

import { RouteName, HEADER, ResponseState } from '../constants'
import { useTranslate, useCurrentRoute } from '../hooks'
import { AuthApi } from '../api'

export default {
  name: HEADER,
  components: {
    LanguageSelect,
    ThemeSelect,
  },
  setup() {
    const t = useTranslate(HEADER)
    const currentRoute = useCurrentRoute()
    const router = useRouter()
    const isLoginPage = computed(
      () => currentRoute.value.name === RouteName.LOGIN,
    )

    const logout = async () => {
      const { state } = await AuthApi.logout()

      state === ResponseState.OK && router.push({ name: RouteName.LOGIN })
    }

    return {
      RouteName,
      isLoginPage,
      t,
      logout,
    }
  },
}
</script>
