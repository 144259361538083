import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowLeft,
  faDesktop,
  faXmark,
  faPlus,
  faPencil,
  faWindowRestore,
  faUpload,
  faUsers,
  faTrash,
  faRightLeft,
  faFilm,
  faChessKnight,
  faColumns,
  faCheck,
  faCopy,
  faRemove,
  faEdit,
  faTrashCan,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faAnglesLeft,
  faAnglesRight,
  faAlignJustify,
  faFile,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
// import {} from '@fortawesome/free-brands-svg-icons'
import FontAwesomeIcon from '@/libs/FontAwesomeIcon.vue'

library.add(
  faDesktop,
  faPlus,
  faPencil,
  faXmark,
  faArrowLeft,
  faArrowRight,
  faWindowRestore, // Categories 'window-restore'
  faUpload, // Upload 'upload'
  faUsers, // Users 'users'
  faTrash, // in case of hard delete
  faRightLeft, // change category 'right-left'
  faFilm, // slideshow
  faChessKnight, // game
  faColumns, // back to the list
  faCheck,
  faCopy,
  faEdit,
  faRemove,
  faTrashCan,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAnglesLeft,
  faAnglesRight,
  faAlignJustify, //csm list
  faFile, // CMS pages
  faAngleUp,
  faEye, // preview
)

export { FontAwesomeIcon }
