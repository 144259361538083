import * as ComponentName from '@/constants/componentName'
import * as ModalName from '@/constants/modalName'

export const messages = {
  [ComponentName.CATEGORY_GAME]: {
    game_list: '"{categoryName}" Kategorie Spieleliste',
    add_game_to: `Spiel hinzufügen zu "{categoryName}"`,
    no_game: 'Es gibt noch keine Spiele zum Rendern.',
  },
  [ComponentName.CATEGORY_LIST]: {
    id: 'ID',
    name: 'Name',
    url_slug: 'URL-Slug',
    image: 'Bild',
    actions: 'Aktionen',
    categories: 'Kategorien',
    add_new_category: 'neue Kategorie hinzufügen',
    no_category: 'Es gibt noch keine Kategorie zum Rendern.',
    description: 'Bezeichnung',
  },
  [ComponentName.CMS_COMPONENT]: {
    cms_title: 'Content-Management-System',
    empty_list: 'Es sind keine Elemente zum Rendern vorhanden.',
    id: 'ID',
    name: 'Name',
    active: 'aktiv',
    actions: 'Aktionen',
    add_cms_menu: 'CMS-Menü hinzufügen',
    add_cms_item: 'CMS-Element hinzufügen',
    remote_link: 'Remote-Link',
    route_path: 'Routenpfad',
    slug: 'Schnecke',
    text: 'Text',
    type: 'Typ',
  },
  [ComponentName.CMS_ITEM_TABLE]: {
    empty_list: 'Es sind keine Elemente zum Rendern vorhanden.',
  },
  [ComponentName.CMS_SUBLIST_TABLE]: {
    empty_list: 'Es sind keine Elemente zum Rendern vorhanden.',
    id: 'ID',
    title: 'Titel',
    active: 'aktiv',
    actions: 'Aktionen',
    type: 'Typ',
    redirectUrl: 'Weiterleitungs-URL',
    cmsPage: 'CMS-Seiten',
  },
  [ComponentName.CMS_PAGE_VIEW]: {
    cms_page: 'CMS-Seiten',
    add_page: 'Seite hinzufügen',
    no_page: 'Es gibt keine Seiten zum Rendern.',
    id: 'ID',
    name: 'Name',
    actions: 'Aktionen',
    active: 'Aktiv',
  },
  [ComponentName.FILE_FIELD]: {
    choose_file: 'Datei wählen',
  },
  [ComponentName.GAME]: {
    all_games: 'alle Spiele',
    add_new_game: 'neues Spiel hinzufügen',
    no_game: 'Es gibt noch keine Spiele zum Rendern.',
  },
  [ComponentName.GAME_TABLE]: {
    id: 'id',
    name: 'Name',
    category: 'Kategorie',
    url_slug: 'URL-Slug',
    logo_hor_url: 'horizontale Logo-URL',
    logo_ver_url: 'vertikale Logo-URL',
    new: 'neu',
    hot: 'heiss',
    recommended: 'empfohlen',
    email: 'Email',
    actions: 'Aktionen',
  },
  [ComponentName.HEADER]: {
    casino_admin_panel: 'Casino-Admin-Panel',
    logout: 'Ausloggen',
  },
  [ComponentName.PAGINATION]: {
    rows_per_page: 'Zeilen pro Seite:',
    of: 'von',
  },
  [ComponentName.PANEL_MENU]: {
    categories: 'Kategorien',
    cms_page: 'CMS-Seiten',
    slideshow: 'Diashow',
    users: 'Benutzer',
    games: 'Spiele',
    cms: 'CMS',
  },
  [ComponentName.LOGIN]: {
    login: 'Anmeldung',
    password: 'Passwort',
    submit: 'Einreichen',
  },
  [ComponentName.SLIDESHOW]: {
    slideshow: 'Diashow',
    style: 'Stil',
    actions: 'Aktionen',
    add_new_slide: 'neue Folie hinzufügen',
    no_slide: 'Es gibt noch keine Diashow zum Rendern.',
  },
  [ComponentName.TEXT_EDITOR]: {
    show_layout: 'Anordnung zeigen',
  },
  [ComponentName.USER]: {
    all_users: 'alle Benutzer',
    add_new_user: 'Neuen Benutzer hinzufügen',
    no_user: 'Es gibt noch keinen Benutzer zum Rendern.',
    id: 'id',
    username: 'Benutzername',
    email: 'email',
    created: 'erstellt',
    isActive: 'aktiv',
    actions: 'Aktionen',
  },
  [ModalName.ADD_SLIDESHOW_MODAL]: {
    add_slide: 'Folie hinzufügen',
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    choose_file_first: 'Wählen Sie zuerst eine Datei aus',
  },
  [ModalName.ADD_CMS_PAGE_MODAL]: {
    add_page: 'Seite hinzufügen',
    title: 'Titel',
    content: 'Inhalt',
    isActive: 'aktiv',
    urlSlug: 'URL-Slug',
    pageTitle: 'Seitentitel',
    pageKeywords: 'Seiten-Keywords',
    pageDescription: 'Seitenbeschreibung',
    pageRobots: 'Page-Roboter',
    add: 'hinzufügen',
    cancel: 'stornieren',
  },
  [ModalName.EDIT_GAME_MODAL]: {
    edit_game: 'Spiel #{id} bearbeiten',
    edit: 'Bearbeiten',
    cancel: 'Absagen',
    name: 'Name',
    category: 'Kategorie',
    urlSlug: 'URL-Slug',
    logoHorizontalUrl: 'Horizontale Logo-URL',
    logoVerticalUrl: 'Vertikale Logo-URL',
    recommended: 'Empfohlen',
    email: 'Email',
    new: 'Neu',
    hot: 'Heiss',
  },
  [ModalName.EDIT_USER_MODAL]: {
    edit_user_title: 'Benutzer #{id} bearbeiten',
    username: 'Benutzername',
    active: 'Aktiv',
    edit: 'bearbeiten',
    cancel: 'Abbrechen',
  },
  [ModalName.REMOVE_USER_MODAL]: {
    remove_user_title: 'Benutzer #{id} entfernen',
    text: 'Sind Sie sicher, dass Sie "{username}" entfernen möchten?',
    remove: 'Löschen',
    cancel: 'Abbrechen',
  },
  [ModalName.ADD_CATEGORY_MODAL]: {
    add_category: 'Kategorie hinzufügen',
    name: 'Name',
    urlSlug: 'URL-Slug',
    description: 'Beschreibung',
    image: 'Bild',
    add: 'hinzufügen',
    cancel: 'Abbrechen',
  },
  [ModalName.REMOVE_CATEGORY_MODAL]: {
    remove_category_title: 'Kategorie #{id} entfernen',
    text: 'Möchten Sie "{category}" wirklich entfernen??',
    remove: 'Löschen',
    cancel: 'Abbrechen',
  },
  [ModalName.REMOVE_CMS_PAGE_MODAL]: {
    remove_cms_page_title: 'CMS-Seite #{id} entfernen',
    text: 'Möchten Sie "{name}" wirklich entfernen??',
    remove: 'Löschen',
    cancel: 'Abbrechen',
  },
  [ModalName.EDIT_CATEGORY_MODAL]: {
    edit_category_title: 'Kategorie #{id} bearbeiten',
    name: 'Name',
    urlSlug: 'URL-Slug',
    description: 'Beschreibung',
    image: 'Bild',
    edit: 'bearbeiten',
    cancel: 'Abbrechen',
  },
  [ModalName.EDIT_CMS_PAGE_MODAL]: {
    edit_cms_page_title: 'CMS-Seite #{id} bearbeiten',
    title: 'Titel',
    content: 'Inhalt',
    isActive: 'aktiv',
    urlSlug: 'URL-Slug',
    pageTitle: 'Seitentitel',
    pageKeywords: 'Seiten-Keywords',
    pageDescription: 'Seitenbeschreibung',
    pageRobots: 'Page-Roboter',
    edit: 'bearbeiten',
    cancel: 'Abbrechen',
  },
  [ModalName.ADD_CMS_MENU_MODAL]: {
    add_cms_menu: 'CMS-Menü hinzufügen',
    name: 'Name',
    active: 'Aktiv',
    type: 'Typ',
    add: 'hinzufügen',
    cancel: 'stornieren',
  },
  [ModalName.EDIT_CMS_MENU_MODAL]: {
    edit_cms_menu: 'CMS-Menü bearbeiten',
    name: 'Name',
    active: 'Aktiv',
    type: 'Typ',
    edit: 'bearbeiten',
    cancel: 'stornieren',
  },
  [ModalName.REMOVE_CMS_MENU_MODAL]: {
    remove_cms_title: 'CMS-Menü #{id} entfernen',
    text: 'Sind Sie sicher, dass Sie "{name}" entfernen möchten?',
    remove: 'entfernen',
    cancel: 'stornieren',
  },
  [ModalName.ADD_CMS_ITEM_MODAL]: {
    add_cms_item: 'CMS-Element hinzufügen',
    title: 'Titel',
    redirectUrl: 'Umleitungs-URL',
    cmsMenu: 'CMS-Menü',
    parent: 'Eltern',
    cmsPage: 'CMS-Seiten',
    type: 'Typ',
    add: 'hinzufügen',
    cancel: 'stornieren',
    active: 'Aktiv',
  },
  [ModalName.REMOVE_CMS_ITEM_MODAL]: {
    remove_cms_item_title: 'CMS-Element #{id} entfernen',
    text: 'Sind Sie sicher, dass Sie "{title}" entfernen möchten?',
    remove: 'entfernen',
    cancel: 'stornieren',
  },
  [ModalName.EDIT_CMS_ITEM_MODAL]: {
    edit_cms_item: 'CMS-Element bearbeiten',
    title: 'Titel',
    redirectUrl: 'Umleitungs-URL',
    cmsMenu: 'CMS-Menü',
    parent: 'Eltern',
    cmsPage: 'CMS-Seiten',
    type: 'Typ',
    edit: 'bearbeiten',
    cancel: 'stornieren',
    active: 'Aktiv',
  },
  [ModalName.REARRANGE_CMS_ITEM_MODAL]: {
    rearrange_cms_items: `CMS-Elemente in "{name}" neu anordnen`,
    save: 'sparen',
    cancel: 'stornieren',
  },
}
