import { createRouter, createWebHistory } from 'vue-router'

import { RoutePath } from '@/constants'
import { routes } from './routes'

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

router.beforeEach(async (to, from, next) => {
  const supportedLocaleList = process.env.VUE_APP_SUPPORTED_LOCALE.split(',')
  const fallbackLang = supportedLocaleList[0]
  const lang = to?.params?.lang
  const isLogged = false //TODO
  const fallbackPath = `/${fallbackLang}/${
    isLogged ? RoutePath.PANEL : RoutePath.LOGIN
  }`
  const isUnsupportedLang = !supportedLocaleList.includes(lang)
  const isDirectLocalePath = to.fullPath === `/${to.params.lang}`

  if (isUnsupportedLang || isDirectLocalePath) {
    next({ path: fallbackPath })
    return
  }

  window.i18n.global.locale.value !== lang &&
    (window.i18n.global.locale.value = lang)
  next()
})
