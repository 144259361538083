<template>
  <div class="form-group cap-form-input-container cap-input cap-dropdown">
    <label for="categoryId">{{ label }}</label>
    <div class="cap-select-container">
      <Field
        :id="name"
        :name="name"
        as="select"
        ref="fieldRef"
        :v-model="value"
        class="form-control form-control cap-select"
      >
        <option
          v-for="{ option, value } in options"
          :key="value"
          :value="value"
          class="cap-select-option"
        >
          {{ option }}
        </option>
      </Field>
      <!-- <div @click="toggle" class="cap-dropdown-arrow">
        <svg
          width="12"
          height="6"
          viewBox="0 0 12 6"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 6 6 C 5.9 6 5.8 6 5.7 5.9 L 1 1 C 0 0 0 0 0 0 C 0.3 0 0.5 0 11 0 H 12 L 11 0 C 11 0 11 0 11 0 C 12 0 12 0 12 0 L 6.3 5.9 C 6.2 6 6.1 6 6 6 Z"
          />
        </svg>
      </div> -->
    </div>
    <ErrorMessage :name="name" class="cap-error-message" />
  </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'

import { SELECT_FIELD } from '../constants'

export default {
  name: SELECT_FIELD,
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    label: String,
    name: String,
    value: String,
    options: Array,
  },
}
</script>
