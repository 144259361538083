<template>
  <div class="cap-modal cap-modal-wide">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isPending }"
          :disabled="isPending"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">{{ t('edit_cms_page_title', { id }) }}</h5>
        </div>

        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField :label="t('title')" name="title" :value="title" />
            <TextEditor label="Content" name="content" :value="content" />
            <CheckboxField
              :label="t('isActive')"
              name="isActive"
              :value="isActive"
            />
            <TextField :label="t('urlSlug')" name="urlSlug" :value="urlSlug" />
            <TextField
              :label="t('pageTitle')"
              name="pageTitle"
              :value="pageTitle"
            />
            <TextField
              :label="t('pageKeywords')"
              name="pageKeywords"
              :value="pageKeywords"
            />
            <TextField
              :label="t('pageDescription')"
              name="pageDescription"
              :value="pageDescription"
            />
            <TextField
              :label="t('pageRobots')"
              name="pageRobots"
              :value="pageRobots"
            />
            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                @click="onSubmit"
                type="submit"
              >
                <span>{{ t('edit') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                :disabled="isPending"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'
import TextEditor from '../components/TextEditor.vue'
import TextField from '../components/TextField'
import CheckboxField from '../components/CheckboxField'

import {
  EDIT_CMS_PAGE_MODAL,
  ResponseState,
  CMS_PAGE_SUCCESS_MESSAGE,
} from '../constants'
import { useModal, useTranslate } from '../hooks'
import { CmsPageApi } from '../api'
import { load } from '../helpers'

export const initialValues = {
  title: '',
  content: '',
  isActive: false,
  urlSlug: '',
  pageTitle: '',
  pageKeywords: '',
  pageDescription: '',
  pageRobots: '',
}

export const validationSchema = yup.object({
  title: yup.string().required().max(255, 'Cannot be more than 255 characters'),
  content: yup
    .string()
    .required()
    .max(50000, 'Cannot be more than 50000 characters'),
  isActive: yup.bool().required(),
  urlSlug: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
  pageTitle: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
  pageKeywords: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
  pageDescription: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
  pageRobots: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
})

export default {
  name: EDIT_CMS_PAGE_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextEditor,
    TextField,
    CheckboxField,
  },
  setup(_, context) {
    const t = useTranslate(EDIT_CMS_PAGE_MODAL)
    const { message, isPending, data, closeModal } = useModal(context)
    const { id, name } = data ?? {}

    const { meta, isSubmitting, handleSubmit, resetForm, setErrors } = useForm({
      initialValues,
      validationSchema,
    })

    const getInitialValues = async () => {
      const { getData } = await load({
        getData: CmsPageApi.getById({ id }),
      })
      const { msg, state, data: pageData } = getData

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      resetForm({
        values: { ...pageData },
      })
    }

    const onSubmit = async ({ ...formData }) => {
      const request = {
        id,
        ...formData,
      }
      const { editPage } = await load({
        editPage: CmsPageApi.edit(request),
      })
      const { msg, state, data } = editPage

      if (state === ResponseState.ERROR) {
        message.value = msg

        Object.keys(data?.errors || {}).forEach((key) => {
          setErrors({ [key]: data.errors[key].msg })
        })

        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CMS_PAGE_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    onBeforeMount(getInitialValues)

    return {
      id,
      message,
      name,
      isPending,
      t,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      meta,
      isSubmitting,
      ...initialValues,
    }
  },
}
</script>
