<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isSubmitting }"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">{{ t('edit_user_title', { id }) }}</h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField
              :label="t('username')"
              name="username"
              :value="username"
              :autocomplete="t('username')"
            />

            <CheckboxField
              :label="t('active')"
              name="isActive"
              :value="isActive"
            />

            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
              >
                <span>{{ t('edit') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate'
import * as yup from 'yup'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import Message from '../components/Message.vue'
import TextField from '../components/TextField'

import { EDIT_USER_MODAL } from '../constants/modalName'
import { ResponseState, USER_SUCCESS_MESSAGE } from '../constants'
import { load } from '../helpers'
import { UserApi } from '../api'
import { useTranslate, useModal } from '../hooks'

const validationSchema = yup.object({
  username: yup
    .string()
    .required('Username is required.')
    .max(255, 'Cannot be more than 255 characters'),
  isActive: yup.bool(),
})

export default {
  name: EDIT_USER_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextField,
  },
  setup(props, context) {
    const t = useTranslate(EDIT_USER_MODAL)
    const { message, closeModal, data } = useModal(context)
    const { id, username, isActive } = data ?? {}
    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues: {
        username,
        isActive,
      },
      validationSchema,
    })

    const onSubmit = handleSubmit(async ({ username, isActive }) => {
      const request = {
        id,
        username,
        isActive,
      }
      const loaderList = {
        userData: UserApi.edit(request),
      }

      const { userData } = await load(loaderList)
      const { msg, state } = userData

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(USER_SUCCESS_MESSAGE, msg)

      closeModal()
    })

    return {
      message,
      meta,
      username,
      isActive,
      id,
      isSubmitting,
      t,
      closeModal,
      onSubmit,
    }
  },
}
</script>
