<template>
  <div class="cap-panel-view">
    <div class="cap-panel-view-header">{{ t('categories') }}</div>

    <div class="cap-panel-view-add-button-group">
      <button @click="openAddCategoryModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_new_category') }}</span>
      </button>
    </div>

    <div v-if="isNilList">
      <Loading />
    </div>
    <Message
      v-else-if="updateListMessage"
      :message="updateListMessage"
      :hasBottom="true"
    />
    <div v-else-if="isEmptyList">
      {{ t('no_category') }}
    </div>
    <div v-else>
      <Message
        :message="editCategoryMessage"
        :type="MessageType.SUCCESS"
        :hasBottom="true"
        @close="closeEditCategoryMessage"
      />
      <table class="cap-table">
        <tr class="cap-table-head">
          <th class="cap-table-cell-small">{{ t('id') }}</th>
          <th class="cap-table-cell">{{ t('name') }}</th>
          <th class="cap-table-cell">{{ t('url_slug') }}</th>
          <th class="cap-table-cell">{{ t('description') }}</th>
          <th class="cap-table-cell">{{ t('image') }}</th>
          <th class="cap-table-cell-small">{{ t('actions') }}</th>
        </tr>

        <tr
          v-for="({ name, urlSlug, description, image },
          id) in categoryDictionary"
          :key="id"
        >
          <th class="cap-table-cell-small">{{ id }}</th>
          <th class="cap-table-cell">
            <Route :to="{ name: RouteName.CATEGORY, query: { id } }">
              {{ name }}
            </Route>
          </th>
          <th class="cap-table-cell">{{ urlSlug }}</th>
          <th class="cap-table-cell">{{ description }}</th>
          <th class="cap-table-cell">
            <div v-if="image" class="cap-table-cell-svg">
              <div class="cap-table-svg-container">
                <img :src="image" :alt="image" />
              </div>
              <fa
                @click="copyURL(image)"
                icon="copy"
                class="cap-table-url-copy-icon"
              ></fa>
            </div>
          </th>
          <th class="cap-table-cell-small cap-table-btn-group">
            <fa
              @click="
                openEditCategoryModal({ id, name, urlSlug, image, description })
              "
              icon="edit"
              class="cap-table-tick-icon cap-table-action-icon"
            ></fa>
            <fa
              @click="openRemoveCategoryModal({ id, category: name })"
              icon="trash-can"
              class="cap-table-cross-icon cap-table-action-icon"
            ></fa>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import { isNil, isEmpty } from 'ramda'

import Loading from '@/components/Loading'
import Route from '@/components/Route'
import Message from '@/components/Message'

import {
  Module,
  CATEGORY_DICTIONARY,
  CATEGORY_LIST,
  SHOW_MODAL,
  ADD_CATEGORY_MODAL,
  EDIT_CATEGORY_MODAL,
  REMOVE_CATEGORY_MODAL,
  CATEGORY_SUCCESS_MESSAGE,
  RouteName,
  MessageType,
  UPDATE_CATEGORY_DICTIONARY,
  RouteQueryKey,
  ResponseState,
} from '@/constants'
import { getState, commitMutation, copyURL, load } from '@/helpers'
import {
  useTranslate,
  useActions,
  useCurrentRoute,
  useRouteQueryAction,
} from '@/hooks'

export default {
  name: CATEGORY_LIST,
  components: {
    Loading,
    Route,
    Message,
  },
  setup() {
    const t = useTranslate(CATEGORY_LIST)
    const editCategoryMessage = ref('')
    const updateListMessage = ref('')
    const currentRoute = useCurrentRoute()
    const categoryDictionary = computed(() =>
      getState(Module.CATEGORY, CATEGORY_DICTIONARY),
    )
    const isNilList = computed(() => isNil(categoryDictionary.value))
    const isEmptyList = computed(() => isEmpty(categoryDictionary.value))
    const queryParams = computed(() => currentRoute.value.query)
    const routeQueryAction = useRouteQueryAction()

    const [updateCategoryDictionary] = useActions([
      Module.CATEGORY,
      UPDATE_CATEGORY_DICTIONARY,
    ])

    const openAddCategoryModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_CATEGORY_MODAL,
        isStrict: true,
      })

      routeQueryAction.add()
    }

    const openEditCategoryModal = ({
      id,
      name,
      urlSlug,
      description,
      image,
    }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_CATEGORY_MODAL,
        isStrict: true,
        meta: { id, name, urlSlug, description, image },
      })

      routeQueryAction.edit()
    }

    const openRemoveCategoryModal = ({ id, category }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_CATEGORY_MODAL,
        isStrict: true,
        meta: { id, category },
      })

      routeQueryAction.remove()
    }

    const closeEditCategoryMessage = () => {
      editCategoryMessage.value = ''
    }

    const updateList = async () => {
      const { getList } = await load({
        getList: updateCategoryDictionary(),
      })

      const { state, msg } = getList

      if (state === ResponseState.ERROR) {
        updateListMessage.value = msg
      }
    }

    watch(
      () => queryParams,
      (state) => {
        if (state[RouteQueryKey.ACTION]) {
          editCategoryMessage.value && (editCategoryMessage.value = '')
          return
        }

        const newEditCategoryMessage = sessionStorage.getItem(
          CATEGORY_SUCCESS_MESSAGE,
        )

        if (newEditCategoryMessage) {
          editCategoryMessage.value = newEditCategoryMessage
          sessionStorage.removeItem(CATEGORY_SUCCESS_MESSAGE)
          updateList()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    onMounted(updateList)

    return {
      categoryDictionary,
      t,
      isNilList,
      isEmptyList,
      openAddCategoryModal,
      openEditCategoryModal,
      openRemoveCategoryModal,
      closeEditCategoryMessage,
      copyURL,
      RouteName,
      editCategoryMessage,
      MessageType,
      updateListMessage,
    }
  },
}
</script>
