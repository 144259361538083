<template>
  <div class="cap-panel-view">
    <CategoryGame v-if="rendersCategoryItemGameList" />
    <CategoryList v-else />
  </div>
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { isNil } from 'ramda'

import { CATEGORY } from '../../../../constants'
import CategoryGame from './components/CategoryGame'
import CategoryList from './components/CategoryList'

export default {
  name: CATEGORY,
  components: {
    CategoryGame,
    CategoryList,
  },
  setup() {
    const route = useRoute()

    const currentCategoryId = computed(() => route.query?.id)
    const rendersCategoryItemGameList = computed(
      () => !isNil(currentCategoryId.value),
    )

    return {
      rendersCategoryItemGameList,
    }
  },
}
</script>
