<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isSubmitting }"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">{{ t('edit_game', { id }) }}</h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField
              :label="t('name')"
              name="name"
              :value="name"
              :autocomplete="t('name')"
            />
            <SelectField
              :label="t('category')"
              name="category"
              :value="category"
              :options="categoryList"
            />
            <TextField
              :label="t('urlSlug')"
              name="urlSlug"
              :value="urlSlug"
              :autocomplete="t('urlSlug')"
            />
            <TextField
              :label="t('logoHorizontalUrl')"
              name="logoHorizontalUrl"
              :value="logoHorizontalUrl"
              :autocomplete="t('logoHorizontalUrl')"
            />
            <TextField
              :label="t('logoVerticalUrl')"
              name="logoVerticalUrl"
              :value="logoVerticalUrl"
              :autocomplete="t('logoVerticalUrl')"
            />
            <CheckboxField :label="t('hot')" name="hot" :value="hot" />
            <CheckboxField
              :label="t('recommended')"
              name="recommended"
              :value="recommended"
            />
            <CheckboxField :label="t('email')" name="email" :value="email" />
            <CheckboxField :label="t('new')" name="isNew" :value="isNew" />

            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('edit') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, computed } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'

import Button from '../components/Button'
import CloseIcon from '../components/CloseIcon'
import CheckboxField from '../components/CheckboxField'
import Message from '../components/Message'
import SelectField from '../components/SelectField'
import TextField from '../components/TextField'

import {
  Module,
  ResponseState,
  GAME_SUCCESS_MESSAGE,
  CATEGORY_DICTIONARY,
  UPDATE_CATEGORY_DICTIONARY,
  URL_REGEX,
} from '../constants'
import { EDIT_GAME_MODAL } from '../constants/modalName'
import { load } from '../helpers'
import { useState, useActions, useTranslate, useModal } from '../hooks'
import { GameApi } from '../api'

export const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required.')
    .max(255, 'Cannot be more than 255 characters'),
  urlSlug: yup
    .string()
    .required('Slug URL is required.')
    .max(255, 'Cannot be more than 255 characters'),
  logoHorizontalUrl: yup
    .string()
    .matches(URL_REGEX, 'Enter a valid url')
    .required('Horizontal logo URL is required.'),
  logoVerticalUrl: yup
    .string()
    .matches(
      URL_REGEX,
      'Enter a valid url',
    ) /*.required('Vertical logo URL is required.')*/,
  hot: yup.bool(),
  recommended: yup.bool(),
  email: yup.bool(),
  isNew: yup.bool(),
  category: yup
    .string()
    .required()
    .max(255, 'Cannot be more than 255 characters'),
})

export default {
  name: EDIT_GAME_MODAL,
  components: {
    Button,
    CheckboxField,
    CloseIcon,
    Message,
    SelectField,
    TextField,
  },
  setup(props, context) {
    const { message, data, closeModal } = useModal(context)

    const {
      id,
      name,
      categoryId: category,
      urlSlug,
      logoHorizontalUrl,
      logoVerticalUrl,
      hot,
      recommended,
      email,
      isNew,
    } = data ?? {}

    const [categoryDictionary] = useState([
      Module.CATEGORY,
      CATEGORY_DICTIONARY,
    ])

    const categoryList = computed(() => {
      return Object.values(categoryDictionary.value || {}).map(
        ({ id, name }) => ({
          option: name,
          value: id,
        }),
      )
    })

    const t = useTranslate(EDIT_GAME_MODAL)

    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues: {
        name,
        category,
        urlSlug,
        logoHorizontalUrl,
        logoVerticalUrl: logoVerticalUrl ?? '', //TODO: remove, when there are no more games without veritcal url
        hot,
        recommended,
        email,
        isNew,
      },
      validationSchema,
    })

    const [updateCategoryDictionary] = useActions([
      Module.CATEGORY,
      UPDATE_CATEGORY_DICTIONARY,
    ])

    const onSubmit = async ({
      logoHorizontalUrl,
      logoVerticalUrl,
      urlSlug,
      name,
      isNew,
      hot,
      recommended,
      email,
      category,
    }) => {
      const flags = {
        new: isNew,
        hot,
        recommended,
        email,
      }

      const request = {
        id,
        category,
        logoHorizontalUrl,
        logoVerticalUrl,
        urlSlug,
        name,
        flags,
      }

      const loaderList = {
        editGame: GameApi.editGame(request),
      }
      const { editGame } = await load(loaderList)
      const { msg, state } = editGame

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(GAME_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    onBeforeMount(() => {
      !categoryDictionary.value &&
        load({
          categoryDictionary: updateCategoryDictionary(),
        })
    })

    return {
      id,
      t,
      message,
      meta,
      isSubmitting,
      categoryList,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
    }
  },
}
</script>
