import {
  IS_AUTHORIZED,
  SET_IS_AUTHORIZED,
  LOG_IN,
  LOG_OUT,
  ResponseState,
} from '../../constants'
import { AuthApi } from '../../api'

export const auth = {
  namespaced: true,
  state: () => ({
    [IS_AUTHORIZED]: false,
  }),
  mutations: {
    [SET_IS_AUTHORIZED](state, value) {
      state[IS_AUTHORIZED] = value
    },
  },
  actions: {
    async [LOG_IN]({ commit }, body) {
      const config = {
        //TODO: refactor
        data: null,
        error: null,
        msg: '',
        state: null,
      }

      try {
        const { data, state, msg } = await AuthApi.login(body)
        config.data = data
        config.state = state
        config.msg = msg
      } catch (e) {
        console.dir(e)
        config.error = e
      }

      commit(SET_IS_AUTHORIZED, config.state === ResponseState.OK)

      console.log('login config')
      console.log(config)
      return config
    },
    async [LOG_OUT]() {
      // TODO
      //   const config = {
      //     //TODO: refactor
      //     data: null,
      //     error: null,
      //     msg: '',
      //     state: null,
      //   }
      //   try {
      //     const { data, state, msg } = await AuthApi.logout()
      //     config.data = data
      //     config.state = state
      //     config.msg = msg
      //   } catch (e) {
      //     console.dir(e)
      //     config.error = e
      //   }
      //   commit(SET_IS_AUTHORIZED, false)
      //   return config
    },
  },
}
