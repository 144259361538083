import { useRouter } from 'vue-router'

import { RouteQueryValue, RouteQueryKey } from '../constants'
import { useCurrentRoute } from './useCurrentRoute'

const ActionQueryKey = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
  ADD_ITEM: 'ADD_ITEM',
  EDIT_ITEM: 'EDIT_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REARRANGE_ITEMS: 'REARRANGE',
}

const ActionQuery = {
  [ActionQueryKey.ADD]: RouteQueryValue.ADD,
  [ActionQueryKey.EDIT]: RouteQueryValue.EDIT,
  [ActionQueryKey.REMOVE]: RouteQueryValue.REMOVE,
  [ActionQueryKey.ADD_ITEM]: RouteQueryValue.ADD_ITEM,
  [ActionQueryKey.EDIT_ITEM]: RouteQueryValue.EDIT_ITEM,
  [ActionQueryKey.REMOVE_ITEM]: RouteQueryValue.REMOVE_ITEM,
  [ActionQueryKey.REARRANGE_ITEMS]: RouteQueryValue.REARRANGE_ITEMS,
}

export const useRouteQueryAction = () => {
  const router = useRouter()
  const currentRoute = useCurrentRoute()

  const addRouteQuery = (key) => () => {
    router.push({
      name: currentRoute.value.name,
      query: {
        ...currentRoute.value.query,
        [RouteQueryKey.ACTION]: ActionQuery[key],
      },
    })
  }

  return {
    add: addRouteQuery(ActionQueryKey.ADD),
    edit: addRouteQuery(ActionQueryKey.EDIT),
    remove: addRouteQuery(ActionQueryKey.REMOVE),
    addItem: addRouteQuery(ActionQueryKey.ADD_ITEM),
    editItem: addRouteQuery(ActionQueryKey.EDIT_ITEM),
    removeItem: addRouteQuery(ActionQueryKey.REMOVE_ITEM),
    rearrangeItems: addRouteQuery(ActionQueryKey.REARRANGE_ITEMS),
  }
}
