function _dec2hex(dec) {
  return dec.toString(16).padStart(2, '0')
}

function _generateSimpleUUID() {
  return Math.random().toString(36).substr(2, 5)
}

export function generateUUID(len) {
  try {
    if (!window.crypto) {
      return _generateSimpleUUID()
    }

    const arr = new Uint8Array((len || 40) / 2)

    return Array.from(window.crypto.getRandomValues(arr), _dec2hex).join('')
  } catch (e) {
    return _generateSimpleUUID()
  }
}
