<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            Remove Game
          </h5>
        </div>
        <div class="modal-body">
          <span style="color: inherit; user-select: none;">
            Are you sure you want to remove this game?
          </span>

          <div class="modal-button-group">
            <Button buttonType="accent">Remove</Button>
            <Button buttonType="secondary" @click="closeModal">Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import { REMOVE_GAME_MODAL } from '../constants/modalName'

import { useModal } from '../hooks'

export default {
  name: REMOVE_GAME_MODAL,
  components: {
    Button,
    CloseIcon,
  },
  setup(props, context) {
    const { closeModal } = useModal(context)

    return {
      closeModal,
    }
  },
}
</script>
