import { createI18n } from 'vue-i18n'
import { head, tail } from 'ramda'

import { messages as enMessages } from './en'
import { messages as deMessages } from './de'

const langList = process.env.VUE_APP_SUPPORTED_LOCALE.split(',')

const messages = {
  [head(langList)]: enMessages,
  [tail(langList)]: deMessages,
}

export const i18n = createI18n({
  legacy: false,
  locale: head(langList),
  fallbackLocale: head(langList),
  messages,
})

window.i18n = i18n
