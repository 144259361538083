/* eslint-disable no-unused-vars */
import Login from '../views/Login'
import Panel from '../views/Panel'
import MainContent from '../views/MainContent'
import Slideshow from '../views/Panel/views/Slideshow'
import Category from '../views/Panel/views/Category'
import CMS from '../views/Panel/views/CMS'
import CMSPage from '../views/Panel/views/CMSPage'
import Game from '../views/Panel/views/Game'
import User from '../views/Panel/views/User'
import Page404 from '../views/Page404'

import { Resolver } from '../resolvers'

import { RouteName, RoutePath } from '../constants'

export const routes = [
  {
    name: RouteName.LOCALE,
    path: RoutePath.LOCALE,
    component: MainContent,
    children: [
      {
        name: RouteName.LOGIN,
        path: RoutePath.LOGIN,
        component: Login,
      },
      {
        name: RouteName.PANEL,
        path: RoutePath.PANEL,
        component: Panel,
        beforeEnter: Resolver.Panel,
        children: [
          {
            name: RouteName.CATEGORY,
            path: RoutePath.CATEGORY,
            component: Category,
            beforeEnter: Resolver.Category,
          },
          {
            name: RouteName.CMS,
            path: RoutePath.CMS,
            component: CMS,
          },
          {
            name: RouteName.CMS_PAGE,
            path: RoutePath.CMS_PAGE,
            component: CMSPage,
          },
          {
            name: RouteName.GAME,
            path: RoutePath.GAME,
            component: Game,
            beforeEnter: Resolver.Game,
          },
          {
            name: RouteName.SLIDESHOW,
            path: RoutePath.SLIDESHOW,
            component: Slideshow,
          },
          {
            name: RouteName.USER,
            path: RoutePath.USER,
            component: User,
          },
        ],
      },
    ],
  },
  {
    name: RouteName.PAGE_404,
    path: RoutePath.PAGE_404,
    component: Page404,
  },

  {
    path: '/:catchAll(.*)',
    redirect: RoutePath.PAGE_404,
  },
]
