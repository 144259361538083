<template>
  <template v-if="isOpen">
    <tr v-if="hasNoList" class="cap-item-table-row">
      <td colspan="8">{{ t('no_list') }}</td>
    </tr>

    <template v-else>
      <tr
        v-for="{
          id,
          title,
          isActive,
          type,
          redirectUrl,
          cmsPageId,
        } in renderedList"
        :key="id"
        class="cap-item-table-row"
      >
        <td class="cap-table-cell-small">{{ id }}</td>
        <td colspan="2" class="cap-table-cell">{{ title }}</td>
        <td class="cap-table-cell-small">{{ cmsTypeDictionary[type] }}</td>
        <td class="cap-table-cell-small cap-ellipsis">{{ redirectUrl }}</td>
        <td class="cap-table-cell-small">
          {{ cmsPageIdDictionary[cmsPageId] }}
        </td>
        <td class="cap-table-cell-small">
          <div class="cap-table-cell-container-centered">
            <ToggleButton
              :value="isActive"
              @toggle="changeActiveState({ id, isActive })"
            />
          </div>
        </td>

        <td class="cap-table-cell-small cap-table-btn-group">
          <!-- NOTE: type 3 means Slug type -->
          <fa
            v-if="type === 3"
            icon="eye"
            class="cap-table-action-icon cap-table-eye"
          ></fa>
          <fa
            icon="edit"
            @click="
              openEditCmsItemModal({
                id,
                title,
                isActive,
                type,
                cmsMenuIdList,
                redirectUrl,
                cmsPageId,
              })
            "
            class="cap-table-tick-icon cap-table-tick-icon"
          ></fa>
          <fa
            icon="trash-can"
            @click="openRemoveCmsItemModal({ id, title })"
            class="cap-table-cross-icon cap-table-action-icon"
          ></fa>
        </td>
      </tr>
    </template>
  </template>
</template>
<script>
import { computed, toRefs } from 'vue'
import { isNil, isEmpty, pipe, indexBy, prop, values } from 'ramda'

import ToggleButton from '@/components/ToggleButton'

import {
  CMS_ITEM_TABLE,
  SHOW_MODAL,
  Module,
  EDIT_CMS_ITEM_MODAL,
  REMOVE_CMS_ITEM_MODAL,
  ResponseState,
  MessageType,
} from '../../../../../constants'
import { useTranslate, useRouteQueryAction } from '../../../../../hooks'
import { commitMutation, load } from '../../../../../helpers'
import { CmsItemApi } from '../../../../../api'

export default {
  name: CMS_ITEM_TABLE,
  components: {
    ToggleButton,
  },
  props: {
    items: Array,
    isOpen: Boolean,
    cmsMenuId: Number,
    parentId: Number,
    cmsMenuIdList: Array,
    cmsParentIdList: Array,
    cmsPageIdList: Array,
    cmsTypeList: Array,
    cmsTypeDictionary: Object,
    cmsPageIdDictionary: Object,
  },
  setup(props, context) {
    const {
      items: list,
      cmsMenuId,
      parentId,
      cmsMenuIdList,
      cmsParentIdList,
      cmsPageIdList,
      cmsTypeList,
    } = toRefs(props)
    const t = useTranslate(CMS_ITEM_TABLE)
    const routeQueryAction = useRouteQueryAction()

    const hasNoList = computed(() => {
      return isEmpty(list.value) || isNil(list.value)
    })

    const renderedList = computed(() => {
      return pipe(values, indexBy(prop('position')))(list.value || {})
    })

    const openEditCmsItemModal = ({
      id,
      title,
      isActive,
      type,
      redirectUrl,
      cmsPageId,
    }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_CMS_ITEM_MODAL,
        isStrict: true,
        meta: {
          id,
          title,
          isActive,
          type,
          cmsMenuId: cmsMenuId.value,
          parentId: parentId.value,
          cmsMenuIdList: cmsMenuIdList.value,
          cmsParentIdList: cmsParentIdList.value,
          cmsPageIdList: cmsPageIdList.value,
          redirectUrl,
          cmsPageId,
          cmsTypeList: cmsTypeList.value,
        },
      })

      routeQueryAction.editItem()
    }

    const openRemoveCmsItemModal = ({ id, title }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_CMS_ITEM_MODAL,
        isStrict: true,
        meta: { id, title },
      })

      routeQueryAction.removeItem()
    }

    const changeActiveState = async ({ id, isActive }) => {
      const loaderList = {
        changeState: CmsItemApi.changeState({
          id,
          isActive: !isActive,
        }),
      }

      const { changeState } = await load(loaderList)
      const { state, msg } = changeState
      const messateType =
        state === ResponseState.OK ? MessageType.SUCCESS : MessageType.FAILURE

      context.emit('processCMSItemStateChange', { message: msg, messateType })
    }

    return {
      list,
      hasNoList,
      t,
      openEditCmsItemModal,
      openRemoveCmsItemModal,
      changeActiveState,
      renderedList,
    }
  },
}
</script>
