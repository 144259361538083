import { isFunction } from './isFunction'

/**
 * @param {function} fn
 * this will be called if it a function
 *
 * @param {any} args optional
 * these will be passed to fn function as arguments
 **/

export const callFn = (fn, ...args) => {
  return isFunction(fn) ? fn(...args) : null
}
