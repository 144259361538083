<template>
  <div class="cap-panel-view">
    <div class="cap-panel-view-header">{{ t('cms_page') }}</div>
    <div>
      <button @click="openAddCmsModal" class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_page') }}</span>
      </button>
    </div>
    <Message
      :message="editCMSPageMessage"
      :type="editCMSPageMessageType"
      :hasBottom="true"
      @close="closeEditCMSPageMessage"
    />
    <div v-if="isNilList">
      <Loading />
    </div>
    <Message
      v-else-if="listMessage"
      :message="listMessage"
      :hasBottom="true"
    />
    <div v-else-if="isEmptyList">{{ t('no_page') }}</div>
    <table v-else class="cap-table">
      <tr class="cap-table-head">
        <th class="cap-table-cell-small">{{ t('id') }}</th>
        <th class="cap-table-cell">{{ t('name') }}</th>
        <th class="cap-table-cell-small">{{ t('active') }}</th>
        <th class="cap-table-cell-small">{{ t('actions') }}</th>
      </tr>
      <tr v-for="{ id, name, state } in list" :key="id">
        <th class="cap-table-cell-small">{{ id }}</th>
        <th class="cap-table-cell">{{ name }}</th>
        <th class="cap-table-cell-small">
          <div class="cap-table-cell-container-centered">
            <ToggleButton
              :value="state"
              @toggle="changePageState({ id, name, state })"
            />
          </div>
        </th>

        <th class="cap-table-cell-small cap-table-btn-group">
          <fa
            icon="edit"
            @click="openEditCmsModal({ id, name })"
            class="cap-table-tick-icon cap-table-tick-icon"
          ></fa>
          <fa
            icon="trash-can"
            @click="openRemoveCmsModal({ id, name })"
            class="cap-table-cross-icon cap-table-action-icon"
          ></fa>
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import { computed, watch, onMounted, ref } from 'vue'
import { isNil, isEmpty } from 'ramda'

import Message from '@/components/Message'
import Loading from '@/components/Loading'
import ToggleButton from '@/components/ToggleButton'

import {
  Module,
  SHOW_MODAL,
  ADD_CMS_PAGE_MODAL,
  CMS_PAGE_SUCCESS_MESSAGE,
  ResponseState,
  RouteQueryKey,
  MessageType,
  REMOVE_CMS_PAGE_MODAL,
  EDIT_CMS_PAGE_MODAL,
} from '@/constants'
import { CMS_PAGE_VIEW } from '../../../../constants/componentName'
import {
  useTranslate,
  useCurrentRoute,
  useRouteQueryAction,
} from '../../../../hooks'
import { commitMutation, load } from '../../../../helpers'
import { CmsPageApi } from '../../../../api'

export default {
  name: CMS_PAGE_VIEW,
  components: {
    Message,
    Loading,
    ToggleButton,
  },
  setup() {
    const t = useTranslate(CMS_PAGE_VIEW)
    const list = ref(null)
    const editCMSPageMessage = ref('')
    const editCMSPageMessageType = ref(MessageType.SUCCESS)
    const listMessage = ref('')
    const currentRoute = useCurrentRoute()
    const routeQueryAction = useRouteQueryAction()

    const isNilList = computed(() => isNil(list.value))
    const isEmptyList = computed(() => isEmpty(list.value))
    const queryParams = computed(() => currentRoute.value.query)

    const openAddCmsModal = () => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: ADD_CMS_PAGE_MODAL,
        isStrict: true,
      })

      routeQueryAction.add()
    }

    const openEditCmsModal = ({ id, name }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: EDIT_CMS_PAGE_MODAL,
        isStrict: true,
        meta: { id, name },
      })

      routeQueryAction.edit()
    }

    const openRemoveCmsModal = ({ id, name }) => {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: REMOVE_CMS_PAGE_MODAL,
        isStrict: true,
        meta: { id, name },
      })

      routeQueryAction.remove()
    }

    const closeEditCMSPageMessage = () => {
      editCMSPageMessage.value = ''
    }

    const updateList = async () => {
      const loaderList = {
        getList: CmsPageApi.getAll(),
      }

      const { getList } = await load(loaderList)
      const { data, state, msg } = getList
      if (state === ResponseState.ERROR) {
        listMessage.value = msg
        return
      }

      list.value = data.items
    }

    const changePageState = async ({ id, name, state: isActive }) => {
      const loaderList = {
        changeState: CmsPageApi.changeState({ id, name, isActive: !isActive }),
      }

      const { changeState } = await load(loaderList)
      const { state, msg } = changeState

      editCMSPageMessageType.value =
        state === ResponseState.OK ? MessageType.SUCCESS : MessageType.FAILURE
      editCMSPageMessage.value = msg

      if (ResponseState.OK === state) {
        updateList()
      }
    }

    watch(
      () => queryParams,
      (queryParams) => {
        if (queryParams[RouteQueryKey.ACTION]) {
          editCMSPageMessage.value && (editCMSPageMessage.value = '')
          editCMSPageMessageType.value !== MessageType.SUCCESS &&
            (editCMSPageMessageType.value = MessageType.SUCCESS)

          return
        }

        const newEditCMSPageMessage = sessionStorage.getItem(
          CMS_PAGE_SUCCESS_MESSAGE,
        )

        if (newEditCMSPageMessage) {
          editCMSPageMessage.value = newEditCMSPageMessage
          editCMSPageMessageType.value !== MessageType.SUCCESS &&
            (editCMSPageMessageType.value = MessageType.SUCCESS)

          sessionStorage.removeItem(CMS_PAGE_SUCCESS_MESSAGE)
          updateList()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    onMounted(updateList)

    return {
      t,
      openAddCmsModal,
      openEditCmsModal,
      openRemoveCmsModal,
      closeEditCMSPageMessage,
      updateList,
      editCMSPageMessage,
      isNilList,
      isEmptyList,
      MessageType,
      list,
      changePageState,
      editCMSPageMessageType,
      listMessage,
    }
  },
}
</script>
