import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { omit } from 'ramda'

import { RouteQueryKey, Module, CURRENT_MODAL } from '../constants'
import { useCurrentRoute } from './useCurrentRoute'
import { useGetters } from './storeHooks'

export const useModal = (context) => {
  const message = ref('')
  const isPending = ref(false)
  const currentMessageType = ref(null)
  const router = useRouter()
  const currentRoute = useCurrentRoute()
  const [currentModal] = useGetters([Module.MODAL, CURRENT_MODAL])
  const { meta } = currentModal.value

  const closeModal = () => {
    context.emit('close')
    router.push({
      name: currentRoute.name,
      query: omit([RouteQueryKey.ACTION], currentRoute.value.query),
    })
  }

  return {
    message,
    currentMessageType,
    isPending,
    data: meta || {},
    closeModal,
  }
}
