<template>
  <div class="cap-loading-wrapper">
    <div class="cap-loading"></div>
  </div>
</template>

<script>
import { LOADING_COMPONENT } from '@/constants'

export default {
  name: LOADING_COMPONENT,
}
</script>
