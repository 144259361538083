export const Endpoint = {
  API_ADMIN_CATEGORY_ADD: '/api/admin/category/add',
  API_ADMIN_CATEGORY_DELETE: '/api/admin/category/delete/:id',
  API_ADMIN_CATEGORY_EDIT: '/api/admin/category/edit',

  API_ADMIN_CMS_ITEM_ADD: '/api/admin/cms/item/add',
  API_ADMIN_CMS_ITEM_DELETE: '/api/admin/cms/item/delete/:id',
  API_ADMIN_CMS_ITEM_EDIT: '/api/admin/cms/item/edit',
  API_ADMIN_CMS_ITEM_STATE: '/api/admin/cms/item/state',
  API_ADMIN_CMS_ITEM_POSITION: '/api/admin/cms/item/position',

  API_ADMIN_CMS_MENU_ADD: '/api/admin/cms/menu/add',
  API_ADMIN_CMS_MENU_DELETE: '/api/admin/cms/menu/delete/:id',
  API_ADMIN_CMS_MENU_EDIT: '/api/admin/cms/menu/edit',
  API_ADMIN_CMS_MENU_STATE: '/api/admin/cms/menu/state',

  API_ADMIN_CMS_PAGE_ADD: '/api/admin/cms/page/add',
  API_ADMIN_CMS_PAGE_EDIT: '/api/admin/cms/page/edit',
  API_ADMIN_CMS_PAGE_LIST: '/api/admin/cms/page/list',
  API_ADMIN_CMS_PAGE_DELETE: '/api/admin/cms/page/delete/:id',
  API_ADMIN_CMS_PAGE_DETAILS: '/api/admin/cms/page/details/:id',
  API_ADMIN_CMS_PAGE_STATE: '/api/admin/cms/page/state',

  API_ADMIN_CMS_TREE: '/api/admin/cms/tree',

  API_ADMIN_GAME_CATEGORIES: '/api/admin/game/categories',
  API_ADMIN_GAME_CATEGORIES_ID: '/api/admin/game/categories/:id',

  API_ADMIN_GAME_EDIT: '/api/admin/game/edit',
  API_ADMIN_GAME_LIST: '/api/admin/game/list',

  API_ADMIN_LOGIN: '/api/admin/login',
  API_ADMIN_LOGOUT: '/api/admin/logout',

  API_ADMIN_SLIDESHOW_ADD: '/api/admin/slideshow/add',
  API_ADMIN_SLIDESHOW_LIST: '/api/admin/slideshow/list',

  API_ADMIN_USER_EDIT: '/api/admin/user/edit',
  API_ADMIN_USER_LIST: '/api/admin/user/list',
  API_ADMIN_USER_DELETE: '/api/admin/user/delete/:id',
}
