export const cmsTypeList = [
  {
    option: 'remote_link',
    value: '1',
  },
  {
    option: 'route_path',
    value: '2',
  },
  {
    option: 'slug',
    value: '3',
  },
  {
    option: 'text',
    value: '4',
  },
]
