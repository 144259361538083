import { BaseApi } from './base'
import { Endpoint } from '../constants'

export class SlideshowApi extends BaseApi {
  static getAll({ limit, page } = {}) {
    return super.axios.get(Endpoint.API_ADMIN_SLIDESHOW_LIST, {
      params: { limit, page },
    })
  }
  static add(data) {
    return super.axios.post(Endpoint.API_ADMIN_SLIDESHOW_ADD, data)
  }
}
