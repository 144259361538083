<template>
  <div v-if="isPending" class="cap-modal-background cap-global-loader">
    <Loading />
  </div>
</template>
<script>
import { computed, watch, onBeforeMount, onBeforeUnmount } from 'vue'

import Loading from './Loading'

import { Module, IS_PENDING, GLOBAL_LOADER } from '../constants'
import { setBodyOverflow, getState } from '../helpers'

export default {
  name: GLOBAL_LOADER,
  components: {
    Loading,
  },
  setup() {
    const isPending = computed(() => getState(Module.MAIN, IS_PENDING))

    watch(isPending.value, (state) => {
      if (state) {
        window.scrollTo(0, 0) && setBodyOverflow({ hidden: true })
        return
      }
    })

    onBeforeMount(() => {
      setBodyOverflow({ hidden: true })
    })

    onBeforeUnmount(() => {
      setBodyOverflow()
    })

    return {
      isPending,
    }
  },
}
</script>
