<template>
  <div class="form-group cap-form-input-container cap-input">
    <label for="name">{{ label }}</label>
    <Field
      :id="name"
      :name="name"
      :type="type"
      class="form-control form-control"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :v-model="value"
    />
    <ErrorMessage :name="name" class="cap-error-message" />
  </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'

import { TEXT_FIELD } from '../constants'

export default {
  name: TEXT_FIELD,
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    autocomplete: String,
    label: String,
    name: String,
    value: String,
    placeholder: String,
    type: {
      type: String,
      default: 'input',
    },
  },
}
</script>
