import { axios } from '../plugins'
export class BaseApi {
  static axios = axios

  static formatUrl(url, paramObj) {
    let formattedUrl = url

    Object.keys(paramObj).forEach((key) => {
      const pathParamKey = `:${key}`

      if (formattedUrl.includes(pathParamKey)) {
        formattedUrl = formattedUrl.replace(pathParamKey, paramObj[key])
      }
    })

    return formattedUrl
  }
}
