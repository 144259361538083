<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isSubmitting }"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('add_category') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField
              :label="t('name')"
              name="name"
              :value="name"
              :autocomplete="t('name')"
            />
            <TextField
              :label="t('urlSlug')"
              name="urlSlug"
              :value="urlSlug"
              :autocomplete="t('urlSlug')"
            />
            <TextField
              :label="t('description')"
              name="description"
              :value="description"
              :autocomplete="t('description')"
            />
            <TextField
              :label="t('image')"
              name="image"
              :value="image"
              :autocomplete="t('image')"
            />

            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
              >
                {{ t('add') }}
              </Button>
              <Button
                buttonType="secondary"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useForm } from 'vee-validate'

import Button from '../components/Button.vue'
import CloseIcon from '../components/CloseIcon.vue'
import TextField from '../components/TextField'
import Message from '../components/Message'

import {
  CATEGORY_SUCCESS_MESSAGE,
  ResponseState,
  ADD_CATEGORY_MODAL,
} from '../constants'
import { useModal, useTranslate } from '../hooks'
import { CategoryApi } from '../api'
import { load } from '../helpers'
import { validationSchema } from './EditCategory'

const initialValues = {
  name: '',
  urlSlug: '',
  description: '',
  image: '',
}

export default {
  name: ADD_CATEGORY_MODAL,
  components: {
    Button,
    CloseIcon,
    TextField,
    Message,
  },
  setup(props, context) {
    const { message, closeModal } = useModal(context)
    const t = useTranslate(ADD_CATEGORY_MODAL)

    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues,
      validationSchema,
    })

    const onSubmit = async ({ name, urlSlug, description, image }) => {
      const request = {
        name,
        urlSlug,
        description,
        image,
      }

      const { addCategory } = await load({
        addCategory: CategoryApi.add(request),
      })
      const { msg, state } = addCategory

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CATEGORY_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    return {
      message,
      meta,
      isSubmitting,
      t,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      ...initialValues,
    }
  },
}
</script>
