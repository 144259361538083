<template>
  <div :class="modalContent"><slot></slot></div>
</template>
<script>
import { MODAL_CONTENT_COMPONENT } from '@/constants'

export default {
  name: MODAL_CONTENT_COMPONENT,
  props: {
    isActive: Boolean,
    isRemovalPending: Boolean,
  },
  computed: {
    modalContent() {
      return [
        'cap-modal-content',
        this.isActive && !this.isRemovalPending
          ? 'cap-modal-content-active'
          : '',
      ]
    },
  },
}
</script>
