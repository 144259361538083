import { computed } from 'vue'
import { useStore } from 'vuex'
import { pipe, filter, map, path } from 'ramda'

export const useState = function () {
  const store = useStore()

  return pipe(
    filter(Array.isArray),
    map((pathArray) => computed(() => path(pathArray, store.state))),
  )(arguments)
}

export const useMutations = function () {
  const store = useStore()

  return pipe(
    filter(Array.isArray),
    map((item) => {
      const name = item.join('/')

      return (value) => store.commit(name, value)
    }),
  )(arguments)
}

export const useActions = function () {
  const store = useStore()

  return pipe(
    filter(Array.isArray),
    map((item) => {
      const name = item.join('/')

      return (value) => store.dispatch(name, value)
    }),
  )(arguments)
}

export const useGetters = function () {
  const store = useStore()

  return pipe(
    filter(Array.isArray),
    map((item) => {
      const name = item.join('/')

      return computed(() => store.getters[name])
    }),
  )(arguments)
}
