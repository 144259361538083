<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button
          class="cap-modal-close-button"
          :class="{ 'cap-button-disabled': isSubmitting }"
          @click="closeModal"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('edit_category_title', { id }) }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField
              :label="t('name')"
              name="name"
              :value="name"
              :autocomplete="t('name')"
            />
            <TextField
              :label="t('urlSlug')"
              name="urlSlug"
              :value="urlSlug"
              :autocomplete="t('urlSlug')"
            />
            <TextField
              :label="t('description')"
              name="description"
              :value="description"
              :autocomplete="t('description')"
            />
            <TextField
              :label="t('image')"
              name="image"
              :value="image"
              :autocomplete="t('image')"
            />
            <div class="modal-button-group">
              <Button
                :disabled="isSubmitting || !meta.dirty"
                buttonType="primary"
              >
                {{ t('edit') }}
              </Button>
              <Button
                buttonType="secondary"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as yup from 'yup'
import { useForm } from 'vee-validate'

import Button from '../components/Button.vue'
import Message from '../components/Message.vue'
import CloseIcon from '../components/CloseIcon.vue'
import TextField from '../components/TextField'

import {
  URL_REGEX,
  CATEGORY_SUCCESS_MESSAGE,
  ResponseState,
  EDIT_CATEGORY_MODAL,
} from '../constants'
import { useModal, useTranslate } from '../hooks'
import { CategoryApi } from '../api'
import { load } from '../helpers'

export const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required.')
    .max(255, 'Cannot be more than 255 characters'),
  urlSlug: yup
    .string()
    .required('Slug URL is required.')
    .max(255, 'Cannot be more than 255 characters'),
  description: yup.string().max(255, 'Cannot be more than 255 characters'),
  image: yup.string().matches(URL_REGEX, 'Enter a valid url'),
})

export default {
  name: EDIT_CATEGORY_MODAL,
  components: {
    Button,
    CloseIcon,
    Message,
    TextField,
  },
  setup(props, context) {
    const { message, closeModal, data } = useModal(context)
    const t = useTranslate(EDIT_CATEGORY_MODAL)
    const { id, name, urlSlug, description, image } = data ?? {}
    const initialValues = { id, name, urlSlug, description, image }

    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues,
      validationSchema,
    })

    const onSubmit = async ({ name, urlSlug, description, image }) => {
      const request = {
        id,
        name,
        urlSlug,
        description,
        image,
      }

      const { editCategory } = await load({
        editCategory: CategoryApi.edit(request),
      })
      const { msg, state } = editCategory

      if (state === ResponseState.ERROR) {
        message.value = msg
        return
      }

      message.value && (message.value = '')
      sessionStorage.setItem(CATEGORY_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    return {
      id,
      message,
      meta,
      isSubmitting,
      t,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      ...initialValues,
    }
  },
}
</script>
