<template>
  <div class="cap-panel-view">
    <div class="cap-panel-view-header">
      {{ t('game_list', { categoryName }) }}
    </div>

    <div class="cap-panel-view-add-button-group">
      <button class="cap-panel-view-add-button">
        <fa icon="plus"></fa>
        <span>{{ t('add_game_to', { categoryName }) }}</span>
      </button>
    </div>
    <div>
      <div v-if="isPendingList"><Loading /></div>
      <div v-else-if="isEmptyList">{{ t('no_game') }}</div>
      <div v-else>
        <GameTable :list="list" />
        <Paginator
          :modelValue="pagination"
          @update:modelValue="updatePagination"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import GameTable from '@/components/GameTable'
import Paginator from '@/components/Paginator'
import Loading from '@/components/Loading'

import { CATEGORY_GAME, Module, CATEGORY_DICTIONARY } from '@/constants'
import { getState } from '@/helpers'
import { useTranslate, usePagination } from '@/hooks'
import { CategoryApi } from '@/api'

const apiMethod = CategoryApi.getGameListByCategoryID

export default {
  name: CATEGORY_GAME,
  components: {
    GameTable,
    Paginator,
    Loading,
  },
  setup() {
    const t = useTranslate(CATEGORY_GAME)
    const route = useRoute()
    const categoryDictionary = computed(() =>
      getState(Module.CATEGORY, CATEGORY_DICTIONARY),
    )
    const categoryId = computed(() => route.query?.id)
    const categoryName = computed(
      () => categoryDictionary.value?.[categoryId.value]?.name,
    )
    const {
      list,
      pagination,
      isPendingList,
      isEmptyList,
      isNilList,
      updatePagination,
    } = usePagination({ apiMethod })

    return {
      categoryName,
      t,
      isPendingList,
      isEmptyList,
      isNilList,
      list,
      pagination,
      updatePagination,
    }
  },
}
</script>
