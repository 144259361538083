import _axios from 'axios'
import {
  interceptUrl,
  interceptResponse,
  interceptError,
} from '../interceptors'

const createApi = () => {
  const axiosInstance = _axios.create({
    baseURL: process.env.VUE_APP_HOST,
    withCredentials: true,
  })

  axiosInstance.interceptors.request.use(interceptUrl)
  axiosInstance.interceptors.response.use(interceptResponse, interceptError)

  return axiosInstance
}

export const axios = createApi()
