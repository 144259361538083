import { BaseResolver } from './baseResolver'
import { Module } from '../constants'
import { RouteName, IS_AUTHORIZED } from '../constants'
import { getState } from '../helpers'
import router from '@/router'

export class Resolver extends BaseResolver {
  static async Category(to, from, next) {
    next()
  }

  static async Game(to, from, next) {
    next()
  }

  static async Panel({ params, name }, from, next) {
    const isAuthorized = getState(Module.AUTH, IS_AUTHORIZED)

    if (!isAuthorized) {
      return router.push({ name: RouteName.LOGIN, params })
    }

    if (name === RouteName.PANEL) {
      return router.push({ name: RouteName.CATEGORY, params })
    }

    next()
  }
}
