<template>
  <div class="cap-button-loader"><Loading /></div>
</template>

<script>
import Loading from './Loading.vue'
import { BUTTON_LOADER } from '@/constants'

export default {
  name: BUTTON_LOADER,
  components: {
    Loading,
  },
}
</script>
