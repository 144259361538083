<template>
  <div class="cap-paginator">
    <div class="cap-paginator-rows">
      <span>{{ t('rows_per_page') }}</span>
      <span>{{ limit }}</span>
      <div @click="toggleRowSelect" class="cap-paginator-row-select-arrow">
        <fa icon="angle-down" class="cap-paginator-arrow-down"></fa>
      </div>
      <div
        v-if="isRowSelectOpen"
        class="cap-paginator-row-select-list-container"
      >
        <ul class="cap-paginator-row-select-list">
          <li v-for="limit in limitList" :key="limit">
            <button @click="setLimit({ newLimit: limit })">
              {{ limit }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="cap-paginator-page-select-container">
      <span>{{ firstRow }}</span>
      <span>-</span>
      <span>{{ lastRow }}</span>
      <span>{{ t('of') }}</span>
      <span>{{ total }}</span>
      <button
        @click="backMax"
        :disabled="isFirstPage"
        class="cap-paginator-select-btn cap-paginator-btn-far-back"
        :class="{ 'cap-paginator-select-btn-disabled': isFirstPage }"
      >
        <fa icon="angles-left" class="cap-paginator-arrow-double"></fa>
      </button>
      <button
        @click="back"
        :disabled="isFirstPage"
        class="cap-paginator-select-btn"
        :class="{ 'cap-paginator-select-btn-disabled': isFirstPage }"
      >
        <fa icon="angle-left" class="cap-paginator-arrow"></fa>
      </button>
      <button
        @click="next"
        :disabled="isLastPage"
        class="cap-paginator-select-btn"
        :class="{ 'cap-paginator-select-btn-disabled': isLastPage }"
      >
        <fa icon="angle-right" class="cap-paginator-arrow"></fa>
      </button>
      <button
        @click="nextMax"
        :disabled="isLastPage"
        class="cap-paginator-select-btn cap-paginator-btn-far-forward"
        :class="{ 'cap-paginator-select-btn-disabled': isLastPage }"
      >
        <fa icon="angles-right" class="cap-paginator-arrow-double"></fa>
      </button>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { isNil } from 'ramda'

import { PAGINATION } from '../constants'
import { useTranslate } from '../hooks'

const _limitList = [5, 10, 20]

export default {
  name: PAGINATION,
  props: {
    modelValue: Object,
  },
  setup(props, { emit }) {
    const t = useTranslate(PAGINATION)
    const isRowSelectOpen = ref(false)
    const limitList = _limitList

    const limit = computed(() => Number(props.modelValue.limit) || 0)
    const total = computed(() => Number(props.modelValue.total) || 0)
    const page = computed(() => Number(props.modelValue.page) || 0)
    const firstRow = computed(() => limit.value * (page.value - 1) + 1)
    const lastRow = computed(() => {
      const defaultLastRow = firstRow.value - 1 + limit.value
      return defaultLastRow > total.value ? total.value : defaultLastRow
    })
    const isFirstPage = computed(() => firstRow.value === 1)
    const isLastPage = computed(() => lastRow.value === total.value)
    const hasItems = computed(() => isNil(total.value))

    const toggleRowSelect = () =>
      (isRowSelectOpen.value = !isRowSelectOpen.value)

    const setLimit = ({ newLimit, newPage = 1 }) => {
      emit('update:modelValue', {
        ...props.modelValue,
        ...(newLimit ? { limit: newLimit } : { limit: limit.value }),
        ...(newPage ? { page: newPage } : { page: page.value }),
      })

      newLimit && toggleRowSelect()
      window.scrollTo(0, 0)
    }

    const back = () => {
      setLimit({ newPage: page.value - 1 })
    }
    const backMax = () => {
      setLimit({ newPage: 1 })
    }
    const next = () => {
      setLimit({ newPage: page.value + 1 })
    }
    const nextMax = () => {
      const fullPageList = ~~(total.value / limit.value)
      const newPage =
        total.value % limit.value > 0 ? fullPageList + 1 : fullPageList

      setLimit({ newPage })
    }

    return {
      isRowSelectOpen,
      limitList,
      limit,
      page,
      total,
      isFirstPage,
      isLastPage,
      hasItems,
      firstRow,
      lastRow,
      t,
      toggleRowSelect,
      setLimit,
      back,
      backMax,
      next,
      nextMax,
    }
  },
}
</script>
