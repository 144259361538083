<template>
  <div class="cap-modal">
    <div class="modal-dialog cap-modal-dialog">
      <div class="modal-content">
        <button class="cap-modal-close-button" @click="closeModal">
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('add_game') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message
            :message="categoryListMessage"
            :hasBottom="true"
            @close="closeCategoryListMessage"
          />
          <Message :message="message" :hasBottom="true" />
          <form @submit="onSubmit" class="cap-form-data">
            <TextField
              :label="t('name')"
              name="name"
              :value="name"
              :autocomplete="t('name')"
            />
            <SelectField
              :label="t('category')"
              name="category"
              :value="category"
              :options="categoryList"
            />
            <TextField
              :label="t('urlSlug')"
              name="urlSlug"
              :value="urlSlug"
              :autocomplete="t('urlSlug')"
            />
            <TextField
              :label="t('logoHorizontalUrl')"
              name="logoHorizontalUrl"
              :value="logoHorizontalUrl"
              :autocomplete="t('logoHorizontalUrl')"
            />
            <TextField
              :label="t('logoVerticalUrl')"
              name="logoVerticalUrl"
              :value="logoVerticalUrl"
              :autocomplete="t('logoVerticalUrl')"
            />
            <CheckboxField :label="t('hot')" name="hot" :value="hot" />
            <CheckboxField
              :label="t('recommended')"
              name="recommended"
              :value="recommended"
            />
            <CheckboxField :label="t('email')" name="email" :value="email" />
            <CheckboxField :label="t('new')" name="isNew" :value="isNew" />

            <div class="modal-button-group">
              <Button
                buttonType="primary"
                :disabled="isSubmitting || !meta.dirty"
                :rendersLoader="isSubmitting"
                type="submit"
                @click="onSubmit"
              >
                <span>{{ t('add') }}</span>
              </Button>
              <Button
                buttonType="secondary"
                type="button"
                :disabled="isSubmitting"
                @click="closeModal"
              >
                {{ t('cancel') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, computed, ref } from 'vue'
import { useForm } from 'vee-validate'

import Button from '../components/Button'
import CloseIcon from '../components/CloseIcon'
import CheckboxField from '../components/CheckboxField'
import Message from '../components/Message'
import SelectField from '../components/SelectField'
import TextField from '../components/TextField'

import {
  Module,
  ResponseState,
  // GAME_SUCCESS_MESSAGE,
  CATEGORY_DICTIONARY,
  UPDATE_CATEGORY_DICTIONARY,
} from '../constants'
import { ADD_GAME_MODAL } from '../constants/modalName'
import { useState, useActions, useTranslate, useModal } from '../hooks'
import { validationSchema } from './EditGame.vue'
import { load } from '../helpers'
// import { GameApi } from '../api'

const initialValues = {
  name: '',
  category: '',
  urlSlug: '',
  logoHorizontalUrl: '',
  logoVerticalUrl: '',
  hot: '',
  recommended: '',
  email: '',
  isNew: '',
}

export default {
  name: ADD_GAME_MODAL,
  components: {
    Button,
    CheckboxField,
    CloseIcon,
    Message,
    SelectField,
    TextField,
  },
  setup(props, context) {
    const { message, closeModal } = useModal(context)
    const categoryListMessage = ref('')

    const [categoryDictionary] = useState([
      Module.CATEGORY,
      CATEGORY_DICTIONARY,
    ])
    const categoryList = computed(() => {
      return Object.values(categoryDictionary.value || {}).map(
        ({ id, name }) => ({
          option: name,
          value: id,
        }),
      )
    })
    const t = useTranslate(ADD_GAME_MODAL)

    const { meta, isSubmitting, handleSubmit } = useForm({
      initialValues,
      validationSchema,
    })

    const [updateCategoryDictionary] = useActions([
      Module.CATEGORY,
      UPDATE_CATEGORY_DICTIONARY,
    ])

    const getCategoryList = async () => {
      const { getCategoryList } = await load({
        getCategoryList: updateCategoryDictionary(),
      })

      const { state, msg } = getCategoryList

      if (state === ResponseState.ERROR) {
        categoryListMessage.value = msg
      }
    }

    const onSubmit = async ({
      logoHorizontalUrl,
      logoVerticalUrl,
      urlSlug,
      name,
      isNew,
      hot,
      recommended,
      email,
      category,
    }) => {
      const flags = {
        new: isNew,
        hot,
        recommended,
        email,
      }

      const request = {
        category,
        logoHorizontalUrl,
        logoVerticalUrl,
        urlSlug,
        name,
        flags,
      }

      console.log({ request })

      // const loaderList = {
      //   addGame: GameApi.addGame(request),
      // }
      // const { addGame } = await load(loaderList)
      // const { msg, state } = addGame

      // if (state === ResponseState.ERROR) {
      //   message.value = msg
      //   return
      // }

      // message.value && (message.value = '')
      // sessionStorage.setItem(GAME_SUCCESS_MESSAGE, msg)

      closeModal()
    }

    const closeCategoryListMessage = () => {
      categoryListMessage.value = ''
    }

    onBeforeMount(async () => {
      !categoryDictionary.value && getCategoryList()
    })

    return {
      t,
      message,
      meta,
      isSubmitting,
      categoryList,
      closeModal,
      onSubmit: handleSubmit(onSubmit),
      categoryListMessage,
      closeCategoryListMessage,
    }
  },
}
</script>
