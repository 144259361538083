import * as ComponentName from '@/constants/componentName'
import * as ModalName from '@/constants/modalName'

export const messages = {
  [ComponentName.CATEGORY_GAME]: {
    game_list: '"{categoryName}" category game list',
    add_game_to: `add game to "{categoryName}"`,
    no_game: 'There is no games to render yet.',
  },
  [ComponentName.CATEGORY_LIST]: {
    id: 'ID',
    name: 'name',
    url_slug: 'URL slug',
    image: 'image',
    actions: 'actions',
    categories: 'Categories',
    add_new_category: 'add new category',
    no_category: 'There is no category to render yet.',
    description: 'description',
  },
  [ComponentName.CMS_COMPONENT]: {
    cms_title: 'Content Management System',
    empty_list: 'There are no items to render.',
    id: 'ID',
    name: 'name',
    active: 'active',
    actions: 'actions',
    add_cms_menu: 'Add CMS Menu',
    add_cms_item: 'Add CMS Item',
    remote_link: 'Remote Link',
    route_path: 'Route Path',
    slug: 'Slug',
    text: 'Text',
    type: 'type',
  },
  [ComponentName.CMS_ITEM_TABLE]: {
    empty_list: 'There are no items to render.',
  },
  [ComponentName.CMS_SUBLIST_TABLE]: {
    empty_list: 'There are no items to render.',
    id: 'ID',
    title: 'title',
    active: 'active',
    actions: 'actions',
    type: 'type',
    redirectUrl: 'redirect URL',
    cmsPage: 'CMS Page',
  },
  [ComponentName.CMS_PAGE_VIEW]: {
    cms_page: 'CMS Pages',
    add_page: 'add page',
    no_page: 'There are no pages to render.',
    id: 'ID',
    name: 'name',
    actions: 'actions',
    active: 'active',
  },
  [ComponentName.FILE_FIELD]: {
    choose_file: 'choose file',
  },
  [ComponentName.GAME]: {
    all_games: 'all Games',
    add_new_game: 'add new game',
    no_game: 'There is no games to render yet.',
  },
  [ComponentName.GAME_TABLE]: {
    id: 'id',
    name: 'name',
    category: 'category',
    url_slug: 'URL slug',
    logo_hor_url: 'logo horizontal URL',
    logo_ver_url: 'logo vertical URL',
    new: 'new',
    hot: 'hot',
    recommended: 'recommended',
    email: 'email',
    actions: 'actions',
  },
  [ComponentName.HEADER]: {
    casino_admin_panel: 'Casino admin panel',
    logout: 'logout',
  },
  [ComponentName.PANEL_MENU]: {
    categories: 'Categories',
    cms_page: 'CMS Pages',
    slideshow: 'Slideshow',
    users: 'Users',
    games: 'Games',
    cms: 'CMS',
  },
  [ComponentName.PAGINATION]: {
    rows_per_page: 'Rows per page:',
    of: 'of',
  },
  [ComponentName.LOGIN]: {
    login: 'Login',
    password: 'Password',
    submit: 'Submit',
  },
  [ComponentName.SLIDESHOW]: {
    slideshow: 'Slideshow',
    style: 'style',
    actions: 'actions',
    add_new_slide: 'add new slide',
    no_slide: 'There is no slideshow to render yet.',
  },
  [ComponentName.TEXT_EDITOR]: {
    show_layout: 'show layout',
  },
  [ComponentName.USER]: {
    all_users: 'all Users',
    add_new_user: 'add new user',
    no_user: 'There is no user to render yet.',
    id: 'id',
    username: 'username',
    email: 'email',
    created: 'created',
    isActive: 'active',
    actions: 'actions',
  },
  [ModalName.ADD_CMS_PAGE_MODAL]: {
    add_page: 'Add Page',
    title: 'title',
    content: 'content',
    isActive: 'active',
    urlSlug: 'URL slug',
    pageTitle: 'Page Title',
    pageKeywords: 'Page Keywords',
    pageDescription: 'Page Description',
    pageRobots: 'Page Robots',
    add: 'add',
    cancel: 'cancel',
  },
  [ModalName.ADD_SLIDESHOW_MODAL]: {
    add_slide: 'Add Slide',
    add: 'Add',
    cancel: 'Cancel',
    choose_file_first: 'Choose file first',
  },
  [ModalName.ADD_GAME_MODAL]: {
    add_game: 'Add Game',
    add: 'Add',
    cancel: 'Cancel',
    name: 'Name',
    category: 'Category',
    urlSlug: 'URL Slug',
    logoHorizontalUrl: 'Horizontal Logo URL',
    logoVerticalUrl: 'Vertical Logo URL',
    recommended: 'Recommended',
    email: 'Email',
    new: 'New',
    hot: 'Hot',
  },
  [ModalName.EDIT_GAME_MODAL]: {
    edit_game: 'Edit Game #{id}',
    edit: 'Edit',
    cancel: 'Cancel',
    name: 'Name',
    category: 'Category',
    urlSlug: 'URL Slug',
    logoHorizontalUrl: 'Horizontal Logo URL',
    logoVerticalUrl: 'Vertical Logo URL',
    recommended: 'Recommended',
    email: 'Email',
    new: 'New',
    hot: 'Hot',
  },
  [ModalName.EDIT_USER_MODAL]: {
    edit_user_title: 'Edit User #{id}',
    username: 'Username',
    active: 'Active',
    edit: 'edit',
    cancel: 'cancel',
  },
  [ModalName.REMOVE_USER_MODAL]: {
    remove_user_title: 'Remove User #{id}',
    text: 'Are you sure you would like to remove "{username}"?',
    remove: 'remove',
    cancel: 'cancel',
  },
  [ModalName.ADD_CATEGORY_MODAL]: {
    add_category: 'Add Category',
    name: 'Name',
    urlSlug: 'URL Slug',
    description: 'description',
    image: 'image',
    add: 'add',
    cancel: 'cancel',
  },
  [ModalName.REMOVE_CATEGORY_MODAL]: {
    remove_category_title: 'Remove Category #{id}',
    text: 'Are you sure you would like to remove "{category}"?',
    remove: 'remove',
    cancel: 'cancel',
  },
  [ModalName.REMOVE_CMS_PAGE_MODAL]: {
    remove_cms_page_title: 'Remove CMS Page #{id}',
    text: 'Are you sure you would like to remove "{name}"?',
    remove: 'remove',
    cancel: 'cancel',
  },
  [ModalName.EDIT_CATEGORY_MODAL]: {
    edit_category_title: 'Edit Category #{id}',
    name: 'Name',
    urlSlug: 'URL Slug',
    description: 'description',
    image: 'image',
    edit: 'edit',
    cancel: 'cancel',
  },
  [ModalName.EDIT_CMS_PAGE_MODAL]: {
    edit_cms_page_title: 'Edit CMS Page #{id}',
    title: 'title',
    content: 'content',
    isActive: 'active',
    urlSlug: 'URL slug',
    pageTitle: 'Page Title',
    pageKeywords: 'Page Keywords',
    pageDescription: 'Page Description',
    pageRobots: 'Page Robots',
    edit: 'edit',
    cancel: 'cancel',
  },
  [ModalName.ADD_CMS_MENU_MODAL]: {
    add_cms_menu: 'Add CMS Menu',
    name: 'Name',
    active: 'Active',
    type: 'type',
    add: 'add',
    cancel: 'cancel',
  },
  [ModalName.EDIT_CMS_MENU_MODAL]: {
    edit_cms_menu: 'Edit CMS Menu',
    name: 'Name',
    active: 'Active',
    type: 'type',
    edit: 'edit',
    cancel: 'cancel',
  },
  [ModalName.REMOVE_CMS_MENU_MODAL]: {
    remove_cms_title: 'Remove CMS Menu #{id}',
    text: 'Are you sure you would like to remove "{name}"?',
    remove: 'remove',
    cancel: 'cancel',
  },
  [ModalName.ADD_CMS_ITEM_MODAL]: {
    add_cms_item: 'Add CMS Item',
    title: 'title',
    redirectUrl: 'Redirect URL',
    cmsMenu: 'CMS Menu',
    parent: 'Parent',
    cmsPage: 'CMS Page',
    type: 'type',
    add: 'add',
    cancel: 'cancel',
    active: 'active',
  },
  [ModalName.REMOVE_CMS_ITEM_MODAL]: {
    remove_cms_item_title: 'Remove CMS Item #{id}',
    text: 'Are you sure you would like to remove "{title}"?',
    remove: 'remove',
    cancel: 'cancel',
  },
  [ModalName.EDIT_CMS_ITEM_MODAL]: {
    edit_cms_item: 'Edit CMS Item',
    title: 'title',
    redirectUrl: 'Redirect URL',
    cmsMenu: 'CMS Menu',
    parent: 'Parent',
    cmsPage: 'CMS Page',
    type: 'type',
    edit: 'edit',
    cancel: 'cancel',
    active: 'active',
  },
  [ModalName.REARRANGE_CMS_ITEM_MODAL]: {
    rearrange_cms_items: `Rearrange CMS Items in "{name}"`,
    save: 'save',
    cancel: 'cancel',
  },
}
