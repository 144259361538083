import {
  StatusCode,
  RouteName,
  HIDE_MODAL,
  TRIGGER_MODAL_REMOVAL,
  Module,
  CURRENT_MODAL,
} from '../constants'
import router from '../router'
import { commitMutation, getGetter } from '../helpers'

export const interceptError = async (error) => {
  const isUnauthorized = error?.response?.status === StatusCode.UNAUTHORIZED

  if (isUnauthorized) {
    router.push({ name: RouteName.LOGIN })
    const hasModal = getGetter([Module.MODAL, CURRENT_MODAL])

    if (hasModal) {
      await commitMutation([Module.MODAL, HIDE_MODAL])
      commitMutation([Module.MODAL, TRIGGER_MODAL_REMOVAL])
    }
  }

  return error?.response?.data || error?.message
}
