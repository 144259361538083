import { Module, SET_IS_PENDING } from '../constants'
import { commitMutation } from './storeMethods'

export const load = async (indexedList) => {
  commitMutation([Module.MAIN, SET_IS_PENDING], true)

  const keys = Object.keys(indexedList || {})
  const values = Object.values(indexedList || {})
  const indexedResponse = Object.create({})

  try {
    const responseValues = (await Promise.all(values)) || []

    keys.map((key, index) => {
      indexedResponse[key] = responseValues[index]
    })
  } catch (e) {
    console.log(e)
  }

  commitMutation([Module.MAIN, SET_IS_PENDING], false)

  return indexedResponse
}
